import React, { useState, useContext, FormEvent, useEffect } from 'react';
import ReactGA from 'react-ga';
import { useIntl } from 'react-intl';
import { useLocation, useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  StyledTitle,
  Wrapper,
  StyledP,
  StyledButton,
  ConsentTitle,
  ConsentWrapper,
  ConsentsWrapper,
  AcceptAllWrapper,
} from './Consent.style';
import { signup, SignupPurpose } from '../../services/apis/connect';
import { ConfigContext } from '../../contexts/Config';
import { LocaleContext } from '../../contexts/Locale';
import { useQuery } from '../../hooks/useQuery';
import { executeRecaptcha } from '../../services/recaptcha-enterprise';
import RequiredConsentCard from '../../components/RequiredConsentCard';
import OptionalConsentCard from '../../components/OptionalConsentCard';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import { DomElement } from 'htmlparser2';
import { ConsentType } from '../../models/Consent';

const Consent = () => {
  const intl = useIntl();
  const history = useHistory<any>();
  const location = useLocation<any>();
  const { addToast } = useToasts();
  const { config } = useContext(ConfigContext);
  const { language } = useContext(LocaleContext);
  const [optinConsents, setOptinConsents] = useState<ConsentType[]>([]);
  const [mandatoryConsents, setMandatoryConsents] = useState<ConsentType[]>([]);
  const [mandatoriesChecked, setMandatoriesChecked] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState(false);
  const query = useQuery();

  const transform = (node: DomElement, index: number): any => {
    if (node.type === 'tag' && node.name === 'a') {
      node.attribs.class = 'vtmn-link';
      return convertNodeToElement(node, index, transform);
    }
  };

  const getRecaptchaToken = () =>
    JSON.parse(config.GOOGLE_RECAPTCHA_ACTIVE)
      ? executeRecaptcha('consent', config.GOOGLE_RECAPTCHA_ENTERPRISE_KEY)
      : Promise.resolve(null);

  const setParams = (): string => {
    if (location.state.clubConsent) {
      if (location.search) {
        return '&club=true';
      } else {
        return '?club=true';
      }
    }
    return '';
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsFetching(true);

    const consents: SignupPurpose[] = [
      ...mandatoryConsents.map((m: any) => {
        return { id: m.id, enabled: m.checked };
      }),
      ...optinConsents.map((m: any) => {
        return { id: m.id, enabled: m.checked };
      }),
    ];

    ReactGA.event({
      category: 'signup',
      action: 'consent',
      label: consents.join(','),
    });

    try {
      const recaptchaToken = await getRecaptchaToken();
      const response = await signup(
        {
          email: location.state.email,
          list_purposes: consents,
          country: query.get('country') as string,
          store: parseInt(query.get('store') as string),
          club_consent: location.state.clubConsent || false,
        },
        config,
        language as string,
        recaptchaToken
      );
      history.push(`/user-card${location.search}${setParams()}`, {
        email: location.state.email,
        cardNumber: response.data.card_number,
        clubConsent: location.state.clubConsent,
        uuid: response.data.uuid,
        signup: location.state.signup,
      });
    } catch (err) {
      addToast(intl.formatMessage({ id: 'COMMON.ERRORS.DEFAULT' }), {
        appearance: 'error',
      });
      setIsFetching(false);
    }
  };

  const setMandatoryConsentChecked = (id: string): void => {
    const consents = mandatoryConsents.map((c: ConsentType) => {
      if (`mandatory_${c.id}` === id) {
        c.checked = !c.checked;
      }
      return c;
    });
    setMandatoriesChecked(!isSubmitDisabled());
    setMandatoryConsents(consents);
  };

  const setOptinConsentChecked = (id: string): void => {
    const consents = optinConsents.map((c: any) => {
      if (`optional_${c.id}` === id) {
        c.checked = !c.checked;
      }
      return c;
    });
    setOptinConsents(consents);
  };

  const isSubmitDisabled = (): any => {
    return mandatoryConsents.find((c: ConsentType) => !c.checked);
  };

  const setAllChecked = (): void => {
    setMandatoriesChecked(!mandatoriesChecked);
    setMandatoryConsents(
      mandatoryConsents.map((c: ConsentType) => {
        c.checked = !mandatoriesChecked;
        return c;
      })
    );
  };

  useEffect(() => {
    if (location.state.consents) {
      setOptinConsents(
        location.state.consents
          .filter((c: any) => !c.mandatory)
          .map((c: any) => {
            return { ...c, checked: false };
          })
      );
      setMandatoryConsents(
        location.state.consents
          .filter((c: any) => c.mandatory)
          .map((c: any) => {
            return { ...c, checked: false };
          })
      );
    }
  }, [location.state.consents]);

  return (
    location &&
    location.state && (
      <form onSubmit={(e: FormEvent<HTMLFormElement>) => handleSubmit(e)} noValidate>
        <Wrapper>
          <ConsentsWrapper>
            <StyledTitle className="vtmn-typo_title-4">
              {intl.formatMessage({ id: 'PAGES.CONSENT.TITLE' })}
            </StyledTitle>
            <StyledP>
              {ReactHtmlParser(
                `<span>${intl.formatMessage({
                  id: 'PAGES.CONSENT.SUBTITLE',
                })}</span>`,
                { transform }
              )}
            </StyledP>
            {mandatoryConsents.length > 0 ? (
              <div>
                <ConsentTitle className="vtmn-typo_text-2">
                  {intl.formatMessage({ id: 'PAGES.CONSENT.REQUIRED_CONSENT' })}
                </ConsentTitle>
                {mandatoryConsents.length > 1 && (
                  <AcceptAllWrapper>
                    <input
                      type="checkbox"
                      className="vtmn-checkbox"
                      checked={mandatoriesChecked}
                      id="consent-accept-all"
                      onChange={setAllChecked}
                    />
                    <label htmlFor="consent-accept-all" className="vtmn-typo_text-3">
                      {intl.formatMessage({ id: 'PAGES.CONSENT.ACCEPT_ALL' })}
                    </label>
                  </AcceptAllWrapper>
                )}
                {mandatoryConsents.map((consent: any, index: number) => {
                  return (
                    <ConsentWrapper key={index}>
                      <RequiredConsentCard
                        id={`mandatory_${consent.id}`}
                        checked={consent.checked}
                        onChange={setMandatoryConsentChecked}
                        label={ReactHtmlParser(
                          `<span>${intl.formatMessage({
                            id: consent.phrase_label?.toUpperCase(),
                          })}</span>`,
                          { transform }
                        )}
                      />
                    </ConsentWrapper>
                  );
                })}
              </div>
            ) : null}
            {optinConsents.length > 0 ? (
              <div>
                <ConsentTitle className="vtmn-typo_text-2">
                  {intl.formatMessage({ id: 'PAGES.CONSENT.OPTIONAL_CONSENT' })}
                </ConsentTitle>
                {optinConsents.map((consent: any, index: number) => {
                  return (
                    <ConsentWrapper key={index}>
                      <OptionalConsentCard
                        id={`optional_${consent.id}`}
                        checked={consent.checked}
                        onChange={setOptinConsentChecked}
                        label={ReactHtmlParser(
                          `<span>${intl.formatMessage({
                            id: consent.phrase_label?.toUpperCase(),
                          })}</span>`,
                          { transform }
                        )}
                      />
                    </ConsentWrapper>
                  );
                })}
              </div>
            ) : null}
          </ConsentsWrapper>
          <StyledButton
            className="vtmn-btn"
            type="submit"
            disabled={isFetching || isSubmitDisabled()}
            id="consent_submit"
          >
            {intl.formatMessage({ id: 'PAGES.CONSENT.SAVE' })}
          </StyledButton>
        </Wrapper>
      </form>
    )
  );
};

export default Consent;

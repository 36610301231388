import styled from 'styled-components';
import P from '../../components/P';
import Div100vhWithHeader from '../../components/Div100WithHeader';

const Wrapper = styled(Div100vhWithHeader)`
  display: flex;
  flex-direction: column;
  text-align: center;
  box-sizing: border-box;
  margin: 0 auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  flex: 1;

  svg {
    margin: 2rem auto 0;
    width: 224px;
    height: auto;
    min-height: 80px;
  }
`;

const StyledP = styled(P)`
  margin-top: 0.75rem;
  text-align: center;
`;

const StyledClub = styled.div`
  display: flex;
  margin-top: 0.75rem;
  padding: 1.25rem 1rem;
  border: 1pt solid var(--vtmn-color_brand-digital-light-2);
  border-radius: 8pt;
  background-color: var(--vtmn-color_brand-digital-light-3);

  span {
    color: var(--vtmn-color_brand-digital);
    font-size: 1.5rem;
  }

  p {
    padding-left: 0.5rem;
    text-align: start;

    &.club-rtl {
      padding-left: 0;
      padding-right: 0.5rem;
    }
  }
`;

const StyledCode = styled.p`
  margin-top: 2rem;
  font-family: var(--vtmn-typo_font-family);
  font-weight: var(--vtmn-typo_font-weight--bold);
  font-size: 1.25rem;
  line-height: 1.5rem;
  text-align: center;
`;

const StyledWallet = styled.div`
  margin: 1.75rem 0 0;
  text-align: center;
`;

const StyledWalletIcons = styled.div`
  margin-top: 0.75rem;
  display: flex;
  justify-content: center;

  & > img:first-child {
    margin-right: 0.5rem;
  }
`;

const StyledWalletBtn = styled.div`
  margin-top: 2rem;
`;

export {
  Wrapper,
  StyledP,
  StyledClub,
  ContentWrapper,
  StyledCode,
  StyledWallet,
  StyledWalletBtn,
  StyledWalletIcons,
};

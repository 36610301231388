import React, { useContext } from 'react';
import {
  Wrapper,
  StyledButton,
  ImgWrapper,
  BackgroundWrapper,
  LangWrapper,
} from './LangChoice.style';
import { LocaleContext } from '../../contexts/Locale';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import decathlonLogo from '../../assets/images/decathlon-logo.svg';
import langChoice from '../../assets/images/lang_choice/lang_choice.svg';

const LangChoice = (): JSX.Element => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const { availableLanguages } = useContext(LocaleContext);
  const country = new URLSearchParams(window.location.search).get('country');
  const langs = availableLanguages ? availableLanguages.map((el) => el.slice(0, 2)) : [];
  const handleRedirect = () => {
    history.push({
      pathname: '/',
      search: location.search,
    });
  };

  const langList = langs.map((item: string, index: number) => (
    <LocaleContext.Consumer key={index}>
      {({ pickLanguage }) => (
        <StyledButton
          className="vtmn-btn vtmn-btn_variant--primary-reversed"
          onClick={() =>
            pickLanguage ? pickLanguage(`${item}-${country}`, handleRedirect()) : null
          }
          data-locale={`${item}-${country}`}
          key={index}
          id={`lang_choice_${country}`}
        >
          {intl.formatMessage({ id: `PAGES.LANG_CHOICE.${String(item).toUpperCase()}` })}
        </StyledButton>
      )}
    </LocaleContext.Consumer>
  ));

  return (
    <Wrapper>
      <ImgWrapper
        src={decathlonLogo}
        alt={intl.formatMessage({ id: 'COMPONENTS.HEADER.LOGO_ALT' })}
        width="128"
      />
      <LangWrapper>{langList}</LangWrapper>
      <BackgroundWrapper src={langChoice} />
    </Wrapper>
  );
};

export default LangChoice;

import React, { useEffect, useState } from 'react';
import flatten from 'flat';
import { client } from '../../services/client';
import { DEFAULT_LANGUAGE, resolveLanguageWithDefault } from '../../services/i18n';

export type Locale = {
  language: string;
  messages: any;
  areMessagesLoading: boolean;
  availableLanguages: string[];
  defaultCountryLanguageSet: boolean;
  pickLanguage: (lang: string, ...args: any) => void;
};

const LocaleContext = React.createContext<Partial<Locale>>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  pickLanguage: () => {},
});

const LocaleProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [messages, setMessages] = useState(null);
  const [areMessagesLoading, setAreMessagesLoading] = useState(true);
  const resolveCountryLangs: any[] = [];
  const [language, setLanguage] = useState(resolveCountryLangs[0]);
  const [availableLanguages, setAvailableLanguages] = useState(resolveCountryLangs);
  const [defaultCountryLanguageSet, setDefaultCountryLanguageSet] = useState(
    resolveCountryLangs.length <= 1
  );
  const [localesLoaded, setLocalesLoaded] = useState(false);

  /**
   * Load locales.json file
   */
  useEffect(() => {
    if (!localesLoaded) {
      client(`data/translations/locales.json`, {
        headers: {
          Accept: 'application/json',
        },
      })
        .then(({ data }) => {
          setLocalesLoaded(true);
          const country = getURLCountryParam();
          const languageMap = resolveLanguageWithDefault(data, country);
          const urlLanguage = `${getURLLanguageParam()}-${country}`;
          setLanguage(getURLLanguageParam() ? urlLanguage : languageMap[0]);
          setAvailableLanguages(languageMap as string[]);
          setDefaultCountryLanguageSet(languageMap.length <= 1);
        })
        .catch(() => {
          setLanguage(DEFAULT_LANGUAGE);
          setDefaultCountryLanguageSet(true);
        });
    }
  }, [localesLoaded]);

  /**
   * Load language file if messages are not set
   */
  useEffect(() => {
    if (!messages && !!language) {
      setAreMessagesLoading(true);
      client(`data/translations/${language}.json`, {
        headers: {
          Accept: 'application/json',
        },
      })
        .then(({ data }) => {
          setMessages(flatten(data));
        })
        .catch((error) => {
          console.log(error);
          setAreMessagesLoading(false);
        });
    }
  }, [language, messages]);

  useEffect(() => {
    if (messages) {
      setAreMessagesLoading(false);
    }
  }, [language, messages]);

  const state = {
    language,
    messages,
    areMessagesLoading,
    availableLanguages,
    defaultCountryLanguageSet,
    pickLanguage: (lang: string) => {
      setAreMessagesLoading(true);
      client(`data/translations/${lang}.json`, {
        headers: {
          Accept: 'application/json',
        },
      })
        .then(({ data }) => {
          setLanguage(lang);
          setDefaultCountryLanguageSet(true);
          setAreMessagesLoading(false);
          setMessages(flatten(data));
        })
        .catch((error) => {
          console.log(error);
          setAreMessagesLoading(false);
        });
    },
  };

  return <LocaleContext.Provider value={state}>{children}</LocaleContext.Provider>;
};

const getURLCountryParam = () => {
  return new URLSearchParams(window.location.search).get('country');
};

const getURLLanguageParam = () => {
  return new URLSearchParams(window.location.search).get('language');
};

export { LocaleContext, LocaleProvider };

/** @module services/inputs */

/**
 * Check if the input is a phone number.
 * @param {string} input - The input.
 * @returns {boolean} - If input is a phone number returns true, else returns false.
 */
const isPhoneNumber = (input: string): boolean => {
  const phoneNumberRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
  return phoneNumberRegExp.test(input);
};

/**
 * Check if the input is an email.
 * @param {string} input - The input.
 * @returns {boolean} - If input is an email returns true, else returns false.
 */
const isEmail = (input: string): boolean => {
  const emailRegExp = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  return emailRegExp.test(input);
};

/**
 * Check if the input is a valid card number.
 * @param {string} input - The input.
 * @returns {boolean} - If input is a card number returns true, else returns false.
 */
const isCardNumber = (input: string): boolean => {
  const cardRegExp = /^[0-9]{13}$/i;
  return cardRegExp.test(input);
};

export { isPhoneNumber, isEmail, isCardNumber };

import React, { useContext, useEffect, useState } from 'react';
import { initScan, onScan, pauseVideo, playVideo, stopScan } from '../../services/card-scan';
import {
  Wrapper,
  CodeWrapper,
  CodeTextWrapper,
  HeaderWrapper,
  CenterImg,
  CodeTitleWrapper,
  ModalTitle,
  ModalParagraph,
  ModalButtons,
  NeedHelpWrapper,
  ModalSelect,
  ScanZone,
} from './ScanCard.style';
import { useQuery } from '../../hooks/useQuery';
import { ConfigContext } from '../../contexts/Config';
import { executeRecaptcha } from '../../services/recaptcha-enterprise';
import ReactGA from 'react-ga';
import { lookup } from '../../services/apis/connect';
import { useHistory, useLocation } from 'react-router-dom';
import { use100vh } from 'react-div-100vh';
import decathlonLogo from '../../assets/images/decathlon-logo.svg';
import scanCardField from '../../assets/images/card_fid/card-scan-field.svg';
import cardCode from '../../assets/images/card_fid/card-code.svg';
import cardCodeError from '../../assets/images/card_fid/card-code-error.svg';
import { useIntl } from 'react-intl';
import Modal from '../../components/Modal';

const ScanCard = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [temporaryCode, setTemporaryCode] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [authorizationError, setAuthorizationError] = useState(false);
  const [wrongDevice, setWrongDevice] = useState(false);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [swapCamera, setSwapCamera] = useState(false);
  const [activeCamera, setActiveCamera] = useState<MediaDeviceInfo | undefined>(undefined);
  const [tmpDevice, setTmpDevice] = useState<string | undefined>(undefined);
  const [code, setCode] = useState<string>('');
  const [scanInit, setScanInit] = useState<boolean>(false);
  const query = useQuery();
  const { config } = useContext(ConfigContext);
  const country = new URLSearchParams(window.location.search).get('country');
  const history = useHistory();
  const location = useLocation();
  const height100 = use100vh();
  const intl = useIntl();

  const getRecaptchaToken = async () =>
    JSON.parse(config.GOOGLE_RECAPTCHA_ACTIVE)
      ? executeRecaptcha('scanCard', config.GOOGLE_RECAPTCHA_ENTERPRISE_KEY)
      : Promise.resolve(null);

  useEffect(() => {
    if (!scanInit && !authorizationError && !wrongDevice) {
      init(activeCamera?.deviceId);
    }
  }, [scanInit, authorizationError, wrongDevice, activeCamera]);

  useEffect(() => {
    if (!isFetching && scanInit) {
      initNeedHelp();
      onScan().then((code) => {
        setTemporaryCode(code);
        setError(false);
        pauseVideo();
        setIsFetching(true);
      });
    }
  });

  useEffect(() => {
    if (isFetching && !code) {
      onGetCode();
    }
  });

  useEffect(() => {
    if (code) {
      stopScan();
    }
  });

  useEffect(() => {
    history.listen((_, action) => {
      if (action) {
        stopScan();
      }
    });
  });

  const onGetCode = async () => {
    try {
      ReactGA.event({
        category: 'signup',
        action: 'inputCard',
        label: 'cardOK',
      });
      const recaptchaToken = await getRecaptchaToken();
      const store = query.get('store') || 118;
      const accountExists = await lookup(
        { card_number: temporaryCode as string, store, country_code: country as string },
        config,
        recaptchaToken
      );
      if (accountExists) {
        setCode(temporaryCode);
        setTimeout(() => {
          history.push(`/existing-card-account${location.search}`, accountExists.data);
        }, 3000);
      } else {
        setError(true);
        playVideo();
        setTimeout(() => setIsFetching(false), 3000);
      }
    } catch (err) {
      playVideo();
      setError(true);
      setTimeout(() => setIsFetching(false), 3000);
    }
  };

  const goBack = () => {
    history.goBack();
  };

  const init = (deviceId: string | undefined) => {
    initScan('#scan-card-stream', deviceId)
      .then(() => {
        setScanInit(true);
      })
      .catch((err) => {
        if (err.code === 0) {
          setAuthorizationError(true);
        } else {
          setWrongDevice(true);
        }
      });
  };

  const initNeedHelp = () => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      setDevices(devices.filter((d: MediaDeviceInfo) => d.kind === 'videoinput' && d.label));
    });
  };

  const openNeedHelp = () => {
    setError(false);
    pauseVideo();
    setSwapCamera(true);
  };

  const closeNeedHelp = () => {
    setSwapCamera(false);
    playVideo();
  };

  const onSelectChange = (event: any) => {
    setTmpDevice(event.target.value);
  };

  const retry = () => {
    setActiveCamera(devices.find((d: MediaDeviceInfo) => d.deviceId === tmpDevice));
    closeNeedHelp();
    stopScan();
    setScanInit(false);
  };

  return (
    <Wrapper>
      <HeaderWrapper>
        <button
          className="
            vtmn-btn
            vtmn-btn_variant--primary-reversed
            vtmn-btn_size--small
            vtmn-btn--icon-alone
          "
          aria-label="add"
          onClick={goBack}
        >
          <span className="vtmx-arrow-left-s-line"></span>
        </button>
        <ScanZone
          src={decathlonLogo}
          alt={intl.formatMessage({ id: 'COMPONENTS.HEADER.LOGO_ALT' })}
        />
      </HeaderWrapper>
      <div id="scan-card-stream" className="viewport">
        <video
          src=""
          height={height100 || window.innerHeight}
          style={{ height: height100 + 'px' }}
        ></video>
        <canvas className="drawingBuffer"></canvas>
      </div>
      {scanInit && devices.length > 1 && (
        <NeedHelpWrapper>
          <button className="vtmn-btn vtmn-btn_variant--primary-reversed" onClick={openNeedHelp}>
            {intl.formatMessage({ id: 'PAGES.SCAN_CARD.NEED_HELP' })}
          </button>
        </NeedHelpWrapper>
      )}
      {scanInit && <CenterImg src={scanCardField} />}
      {code && (
        <CodeWrapper>
          <img src={cardCode} alt="card" />
          <CodeTextWrapper>
            <p className="vtmn-typo_text-3">
              {intl.formatMessage({ id: 'PAGES.SCAN_CARD.CARD_NUMBER' })}
            </p>
            <p>{code}</p>
          </CodeTextWrapper>
        </CodeWrapper>
      )}
      {error && (
        <CodeWrapper>
          <img src={cardCodeError} alt="card" />
          <CodeTextWrapper>
            <CodeTitleWrapper className="vtmn-typo_text-3">
              {intl.formatMessage({ id: 'PAGES.SCAN_CARD.WRONG_CARD_NUMBER' })}
            </CodeTitleWrapper>
            <p className="vtmn-typo_text-3">
              {intl.formatMessage({ id: 'PAGES.SCAN_CARD.WRONG_CARD_NUMBER_INFO' })}
            </p>
          </CodeTextWrapper>
        </CodeWrapper>
      )}
      <Modal opened={authorizationError}>
        <ModalTitle className="vtmn-typo_text-1">
          {intl.formatMessage({ id: 'PAGES.SCAN_CARD.AUTHORIZATION_TITLE' })}
        </ModalTitle>
        <ModalParagraph>
          {intl.formatMessage({ id: 'PAGES.SCAN_CARD.AUTHORIZATION_INFO' })}
        </ModalParagraph>
        <ModalButtons>
          <button className="vtmn-btn" onClick={goBack}>
            {intl.formatMessage({ id: 'PAGES.SCAN_CARD.AUTHORIZATION_BACK' })}
          </button>
        </ModalButtons>
      </Modal>
      <Modal opened={wrongDevice}>
        <ModalTitle className="vtmn-typo_text-1">
          {intl.formatMessage({ id: 'PAGES.SCAN_CARD.WRONG_DEVICE_TITLE' })}
        </ModalTitle>
        <ModalParagraph>
          {intl.formatMessage({ id: 'PAGES.SCAN_CARD.WRONG_DEVICE_INFO' })}
        </ModalParagraph>
        <ModalButtons className="single">
          <button className="vtmn-btn" onClick={goBack}>
            {intl.formatMessage({ id: 'PAGES.SCAN_CARD.WRONG_DEVICE_BACK' })}
          </button>
        </ModalButtons>
      </Modal>
      <Modal opened={swapCamera}>
        <ModalTitle className="vtmn-typo_text-1">
          {intl.formatMessage({ id: 'PAGES.SCAN_CARD.CHANGE_DEVICE_TITLE' })}
        </ModalTitle>
        <ModalParagraph>
          {intl.formatMessage({ id: 'PAGES.SCAN_CARD.CHANGE_DEVICE_INFO' })}
        </ModalParagraph>
        <ModalSelect className="vtmn-select_container">
          <label htmlFor="device-select">
            {intl.formatMessage({ id: 'PAGES.SCAN_CARD.CHANGE_DEVICE_SELECT' })}
          </label>
          <select
            name="sports"
            id="device-select"
            value={activeCamera?.deviceId || devices[0]?.deviceId}
            onChange={onSelectChange}
          >
            {devices.map((device: MediaDeviceInfo, index: number) => {
              return (
                <option key={index} value={device.deviceId}>
                  {device.label}
                </option>
              );
            })}
          </select>
        </ModalSelect>
        <ModalButtons className="single">
          <button
            className="vtmn-btn"
            onClick={retry}
            disabled={activeCamera && tmpDevice === activeCamera?.deviceId}
          >
            {intl.formatMessage({ id: 'PAGES.SCAN_CARD.CHANGE_DEVICE_RETRY' })}
          </button>
          <button className="vtmn-btn vtmn-btn_variant--ghost" onClick={closeNeedHelp}>
            {intl.formatMessage({ id: 'PAGES.SCAN_CARD.CHANGE_DEVICE_BACK' })}
          </button>
        </ModalButtons>
      </Modal>
    </Wrapper>
  );
};

export default ScanCard;

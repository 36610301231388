import styled from 'styled-components';
import Div100vhWithHeader from '../../components/Div100WithHeader';

const Wrapper = styled(Div100vhWithHeader)`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled.h3`
  margin-top: 1.5rem;
`;

const StyledP = styled.p`
  margin-top: 0.75rem;
`;

const CheckboxWrapper = styled.div`
  margin-top: 1.5rem;
  padding: 1rem;
  border: 1px solid var(--vtmn-color_brand-digital-light-2);
  border-radius: 8px;
`;

const ButtonWrapper = styled.div`
  margin: auto 0 1.5rem;
`;

const StyledButton = styled.button`
  margin-top: 1.5rem;
  width: 100%;
`;

const StyledLink = styled.a`
  display: block;
  margin-top: -0.25rem;
  padding-left: 1.75rem;
  font-weight: var(--vtmn-typo_font-weight--bold);
`;

export { Wrapper, StyledTitle, StyledP, StyledButton, ButtonWrapper, CheckboxWrapper, StyledLink };

import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1.5rem 0;
`;

const StyledImage = styled.img`
  height: 32px;
`;

export { Wrapper, StyledImage };

import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Wrapper,
  StyledTitle,
  Info,
  InputWrapper,
  ScanButton,
  Submit,
  ScanText,
} from './CardSignup.style';
import fidelityCard from '../../assets/images/card_fid/fidelity-card.svg';
import scanCard from '../../assets/images/card_fid/scan-card.svg';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import { ConfigContext } from '../../contexts/Config';
import { executeRecaptcha } from '../../services/recaptcha-enterprise';
import { useQuery } from '../../hooks/useQuery';
import { lookup } from '../../services/apis/connect';
import { isCardNumber } from '../../services/inputs';

const CardSignUp = () => {
  const intl = useIntl();
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();
  const location = useLocation();
  const { config } = useContext(ConfigContext);
  const [isFetching, setIsFetching] = useState(false);
  const query = useQuery();
  const country = new URLSearchParams(window.location.search).get('country');

  const getRecaptchaToken = () =>
    JSON.parse(config.GOOGLE_RECAPTCHA_ACTIVE)
      ? executeRecaptcha('cardSignuUp', config.GOOGLE_RECAPTCHA_ENTERPRISE_KEY)
      : Promise.resolve(null);

  const onScanClick = () => {
    history.push(`/scan-card${location.search}`);
  };

  const onSubmitValidated = async (data: any) => {
    setIsFetching(true);
    try {
      ReactGA.event({
        category: 'signup',
        action: 'inputCard',
        label: 'cardOK',
      });

      const recaptchaToken = await getRecaptchaToken();
      const store = query.get('store') || 118;

      const accountExists = await lookup(
        { card_number: data.card as string, store, country_code: country as string },
        config,
        recaptchaToken
      );

      setIsFetching(false);

      if (accountExists) {
        history.push(`/existing-card-account${location.search}`, accountExists.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Wrapper>
      <StyledTitle className="vtmn-typo_title-5">
        {intl.formatMessage({ id: 'PAGES.CARD_SIGNUP.TITLE' })}
      </StyledTitle>
      <Info>{intl.formatMessage({ id: 'PAGES.CARD_SIGNUP.INFO' })}</Info>
      <ScanButton className="vtmn-btn vtmn-btn_variant--ghost" onClick={onScanClick}>
        <img src={scanCard} alt="scan-card" />
      </ScanButton>
      <ScanText>{intl.formatMessage({ id: 'PAGES.CARD_SIGNUP.SCAN_CARD' })}</ScanText>
      <form onSubmit={handleSubmit(onSubmitValidated)} noValidate>
        <InputWrapper>
          <label className="vtmn-text-input_label" htmlFor="card_signup_input">
            {intl.formatMessage({ id: 'PAGES.CARD_SIGNUP.CARD_NUMBER' })}
          </label>
          <div className="vtmn-text-input_container">
            <input
              name="card"
              className="vtmn-text-input"
              id="card_signup_input"
              type="tel"
              pattern="\d*"
              maxLength={13}
              data-input-mask="'mask': '9999 9999 9999 9999'"
              placeholder={intl.formatMessage({ id: 'PAGES.CARD_SIGNUP.CARD_NUMBER_PLACEHOLDER' })}
              ref={register({
                required: true,
                validate: (value) =>
                  isCardNumber(value) ||
                  intl.formatMessage({ id: 'PAGES.CARD_SIGNUP.CARD_NUMBER_INVALID' }),
              })}
            ></input>
            <span className="vtmx-">
              <img src={fidelityCard} alt="fidelity-card" />
            </span>
          </div>
          {errors.card && (
            <p className="vtmn-text-input_helper-text vtmn-text-input_helper-text--error">
              {errors.card.message}
            </p>
          )}
          {!errors.card && (
            <p className="vtmn-text-input_helper-text">
              {intl.formatMessage({ id: 'PAGES.CARD_SIGNUP.CARD_NUMBER_HELPER' })}
            </p>
          )}
        </InputWrapper>
        <Submit type="submit" className="vtmn-btn" disabled={isFetching}>
          {intl.formatMessage({ id: 'PAGES.CARD_SIGNUP.SUBMIT' })}
        </Submit>
      </form>
    </Wrapper>
  );
};

export default CardSignUp;

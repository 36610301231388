import React from 'react';
import { use100vh } from 'react-div-100vh';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  margin: 0 auto;
  max-width: 650px;
`;

const Div100vhWithHeader = ({ children }: any): JSX.Element => {
  const height100 = use100vh();
  const heightWithHeader = height100 ? height100 - 84 : '100vh';
  return <Wrapper style={{ height: heightWithHeader }}>{children}</Wrapper>;
};

export default Div100vhWithHeader;

import React, { useState, useContext } from 'react';
import ReactGA from 'react-ga';
import {
  StyledTitle,
  Wrapper,
  StyledP,
  StyledButton,
  ButtonWrapper,
  CheckboxWrapper,
  StyledLink,
} from './Club.style';
import { useLocation, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import clubImg from '../../assets/images/consent/club.svg';
import Picture from '../../components/Picture';
import { ConfigContext } from '../../contexts/Config';
import { executeRecaptcha } from '../../services/recaptcha-enterprise';
import { signup } from '../../services/apis/connect';
import { useQuery } from '../../hooks/useQuery';
import { LocaleContext } from '../../contexts/Locale';
import { useToasts } from 'react-toast-notifications';

const Club = () => {
  const history = useHistory();
  const location = useLocation<any>();
  const intl = useIntl();
  const [clubConsent, setClub] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const { config } = useContext(ConfigContext);
  const query = useQuery();
  const { language } = useContext(LocaleContext);
  const { addToast } = useToasts();

  const getRecaptchaToken = () =>
    JSON.parse(config.GOOGLE_RECAPTCHA_ACTIVE)
      ? executeRecaptcha('signUp', config.GOOGLE_RECAPTCHA_ENTERPRISE_KEY)
      : Promise.resolve(null);

  const signupAction = async () => {
    try {
      const recaptchaToken = await getRecaptchaToken();
      const response = await signup(
        {
          email: location.state.email,
          list_purposes: [],
          country: query.get('country') as string,
          store: parseInt(query.get('store') as string),
          club_consent: false,
        },
        config,
        language as string,
        recaptchaToken
      );
      history.push(`/user-card${location.search}`, {
        email: location.state.email,
        uuid: response.data.uuid,
        cardNumber: response.data.card_number,
        clubConsent: false,
        consents: location.state.consents,
        signup: location.state.signup,
      });
    } catch (err) {
      addToast(intl.formatMessage({ id: 'COMMON.ERRORS.DEFAULT' }), {
        appearance: 'error',
      });
      setIsFetching(false);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsFetching(true);

    ReactGA.event({
      category: location.state.cardNumber ? 'signin' : 'signup',
      action: 'clubConsent',
      label: clubConsent.toString(),
    });

    if (clubConsent) {
      redirectToNextPage(clubConsent);
    } else {
      redirectToNextPage(false);
    }
  };

  /**
   * When current user create account, redirect to consent page, else redirect to card
   * @param {boolean} consent
   */
  const redirectToNextPage = (consent: boolean) => {
    const cardNumber = location.state.cardNumber;
    let path;
    if (consent && cardNumber) {
      path = '/club-mail';
    } else if (cardNumber) {
      path = '/user-card';
    } else if (location.state.consents?.length > 0) {
      path = '/consent';
    }
    if (path) {
      history.push(`${path}${location.search}`, {
        email: location.state.email,
        uuid: location.state.uuid,
        cardNumber: cardNumber,
        clubConsent: consent,
        consents: location.state.consents,
      });
    } else {
      signupAction();
    }
  };

  const onChange = (e: any): void => {
    setClub(e?.target?.checked);
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)} noValidate>
      <Wrapper>
        <Picture src={clubImg} alt="club" />
        <StyledTitle className="vtmn-typo_title-3">
          {intl.formatMessage({ id: 'PAGES.CLUB.TITLE' })}
        </StyledTitle>
        <StyledP>{intl.formatMessage({ id: 'PAGES.CLUB.INFO' })}</StyledP>
        <CheckboxWrapper>
          <input
            className="vtmn-checkbox"
            type="checkbox"
            id="club_input"
            checked={clubConsent}
            onChange={onChange}
          ></input>
          <label htmlFor="club_input">{intl.formatMessage({ id: 'PAGES.CLUB.JOIN' })}</label>
          <StyledLink
            className="vtmn-link vtmn-link--standalone"
            target="_blank"
            id="club_link"
            href={intl.formatMessage({
              id: 'PAGES.CLUB.LEGAL_FOOTER.CGU_AND_CONFIDENTIALITY_POLICY_URL',
            })}
          >
            {intl.formatMessage({ id: 'PAGES.CLUB.TERMS' })}
          </StyledLink>
        </CheckboxWrapper>
        <ButtonWrapper>
          <StyledButton
            className="vtmn-btn vtmn-btn_variant--secondary"
            type="submit"
            disabled={isFetching}
            id="club_submit"
          >
            {intl.formatMessage({ id: 'PAGES.CLUB.NEXT' })}
          </StyledButton>
        </ButtonWrapper>
      </Wrapper>
    </form>
  );
};

export default Club;

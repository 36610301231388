/** @module services/i18n */

const DEFAULT_LANGUAGE = 'en';

/**
 * Resolve a language from supported languages and which we asked for.
 * @param {array.<string>} supportedLanguages - The language supported.
 * @param {string} askedFor - The language we asked for.
 * @returns {string} - The language asked for if present. If not, the default language.
 */
const resolveLanguage = (supportedLanguages: string[], country: any): string | string[] =>
  supportedLanguages[country] ? supportedLanguages[country] : [DEFAULT_LANGUAGE];

/**
 * Resolve a language from our My Business' supported languages and which we asked for.
 * @param supportedLanguage
 * @param country the country in which to look the locale for.
 * @returns {string} - The language asked for if present. If not, the default language.
 */
const resolveLanguageWithDefault = (supportedLanguage: string[], country: any): string | string[] =>
  resolveLanguage(supportedLanguage, country);

export { DEFAULT_LANGUAGE, resolveLanguage, resolveLanguageWithDefault };

import styled from 'styled-components';
import Div100vh from 'react-div-100vh';

const Wrapper = styled(Div100vh)`
  display: flex;
  flex-direction: column;
  background: linear-gradient(#007dbc, #012b49);
  padding: 0 1.25rem;
`;

const ImgWrapper = styled.img`
  margin: 1.5rem auto 0;
  border: 1px solid var(--vtmn-color_white);
`;

const LangWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 4rem;
  z-index: 2;
`;

const StyledButton = styled.button`
  margin-bottom: 1.5rem;
`;

const BackgroundWrapper = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: auto;
  height: calc(100vh - 360px);
  object-fit: cover;
  z-index: 1;
`;

export { Wrapper, StyledButton, ImgWrapper, BackgroundWrapper, LangWrapper };

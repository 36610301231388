import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ConfigContext } from '../../contexts/Config';
import { useQuery } from '../../hooks/useQuery';
import { clubConsentValidation } from '../../services/apis/connect';

const ClubMailValid = () => {
  const { config } = useContext(ConfigContext);
  const query = useQuery();
  const token = query.get('token') || '';
  const history = useHistory();
  const country = query.get('country');

  useEffect(() => {
    clubConsentValidation(token, config)
      .then((res) => redirectToCard(res))
      .catch((error) => (error.status === 403 ? redirectToExpired(error) : history.push('/')));
  });

  const redirectToCard = (result: any) => {
    history.push(`/user-card?country=${country}&club=true`, {
      email: result.data.email,
      cardNumber: result.data.card_number,
      clubConsent: true,
    });
  };

  const redirectToExpired = (error: any) => {
    history.push(`/invalid-club-mail?country=${country}&club=true`, {
      email: error.data.email,
      cardNumber: error.data.card_number,
      clubConsent: true,
    });
  };

  return null;
};

export default ClubMailValid;

/** @module services/card-scan */

import Quagga from 'quagga';
import { isCardNumber } from '../inputs';

const initScan = (id: string, deviceId?: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    Quagga.init(
      {
        inputStream: {
          name: 'Live',
          type: 'LiveStream',
          target: document.querySelector(id),
          constraints: {
            facingMode: 'environment',
            deviceId: deviceId || undefined,
          },
          locator: {
            patchSize: 'x-small',
          },
          locate: false,
          area: {
            top: '0%',
            right: '0%',
            left: '0%',
            bottom: '0%',
          },
        },
        decoder: {
          readers: ['code_128_reader'],
        },
      },
      (err: any) => {
        if (err) {
          reject(err);
        } else {
          Quagga.start();
          resolve();
        }
      }
    );
  });
};

const stopScan = (): void => {
  Quagga.stop();
};

const pauseVideo = (): void => {
  const cameraFeed = document.getElementById('scan-card-stream');
  cameraFeed?.getElementsByTagName('video')[0].pause();
};

const playVideo = (): void => {
  const cameraFeed = document.getElementById('scan-card-stream');
  cameraFeed?.getElementsByTagName('video')[0].play();
};

const onScan = (): Promise<string> => {
  return new Promise((resolve) => {
    Quagga.onDetected((res: any) => {
      if (isCardNumber(res?.codeResult?.code)) {
        resolve(res.codeResult.code);
      }
    });
  });
};

export { initScan, stopScan, onScan, pauseVideo, playVideo };

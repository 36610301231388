/** @module services/recaptcha-enterprise */

const SCRIPT_NOT_AVAILABLE = 'Recaptcha script is not available';
let resolver: (value: any) => void;
const scriptId = 'google-recaptcha-enterprise';

const grecaptcha = new Promise((resolve) => {
  resolver = resolve;
});

declare global {
  interface Window {
    grecaptcha: any;
  }
}

const generateGoogleReCaptchaScript = (recaptchakey: string, language?: string, nonce?: string) => {
  const script = document.createElement('script');
  script.id = scriptId;
  script.src = `https://www.recaptcha.net/recaptcha/enterprise.js?render=${recaptchakey}${
    language ? `&hl=${language}` : ''
  }`;
  if (nonce) {
    script.nonce = nonce;
  }
  script.onload = handleOnLoad;
  return script;
};

const executeRecaptcha = async (action: string, recaptchakey: string): Promise<any> => {
  return grecaptcha.then((_grecaptcha: any) => _grecaptcha.execute(recaptchakey, { action }));
};

const handleOnLoad = () => {
  if (!window || !window.grecaptcha || !window.grecaptcha.enterprise) {
    console.warn(SCRIPT_NOT_AVAILABLE);
    return;
  }
  window.grecaptcha.enterprise.ready(() => {
    resolver(window.grecaptcha.enterprise);
  });
};

const injectGoogleReCaptchaScript = (
  recaptchakey: string,
  language?: string,
  nonce?: string
): void => {
  if (document.getElementById(scriptId)) {
    handleOnLoad();
    return;
  }
  const head = document.getElementsByTagName('head')[0];
  const script = generateGoogleReCaptchaScript(recaptchakey, language, nonce);
  head.appendChild(script);
};

export { injectGoogleReCaptchaScript, executeRecaptcha };

import styled from 'styled-components';

const P = styled.p`
  margin: 0;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0;
  color: var(--vtmn-color_black);
  font-family: var(--vtmn-typo_font-family);

  @media (max-width: 380px) {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
`;

export default P;

import styled from 'styled-components';

const Wrapper = styled.div`
  text-align: start;
  padding: 1rem;
  background-color: var(--vtmn-semantic-color_background-secondary);

  & label,
  a {
    font-size: var(--vtmn-typo_text-3-font-size) !important;
    line-height: var(--vtmn-typo_text-3-line-height) !important;
  }
`;

export { Wrapper };

import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  text-align: center;
`;

const HeaderIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;

  & > span {
    color: var(--vtmn-color_grey);
  }

  & > * {
    margin-right: 1rem;

    &:last-child {
      margin: 0;
    }
  }
`;

const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

const InfoWrapper = styled.div`
  padding: 2rem 1.25rem;
`;

const Info = styled.p`
  margin-top: 2rem;
  text-align: start;
`;

const StyledLink = styled.p`
  text-align: start;
  margin-top: 2rem;

  & > span {
    cursor: pointer;
    font-weight: var(--vtmn-typo_font-weight--bold);
    &:before {
      content: ' ';
    }
  }
`;
const StyledSuccess = styled.p`
  margin-top: 2rem;
  text-align: start;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--vtmn-color_success);
`;

const StyledCard = styled.img`
  margin: 0 auto;
`;

export { Wrapper, Header, InfoWrapper, Info, HeaderIcons, StyledLink, StyledSuccess, StyledCard };

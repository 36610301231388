import styled from 'styled-components';

const BackgroundWrapper = styled.div`
  z-index: 2000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--vtmn-color_black);
  overflow: unset;
`;

const CardWrapper = styled.div`
  z-index: 2000;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  min-height: 150px;
  padding: 1rem 2rem 1.5rem;
  background-color: var(--vtmn-color_white);
  max-height: 90%;
  overflow: auto;
`;

const TopWrapper = styled.div`
  margin: 0 auto 1.5rem;
  width: 48px;
  height: 4px;
  border-radius: 2px;
  border: 1px solid var(--vtmn-color_grey-light-2);
  background-color: var(--vtmn-color_grey-light-2);
`;
export { BackgroundWrapper, CardWrapper, TopWrapper };

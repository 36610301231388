import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

const Head = (): JSX.Element => {
  const intl = useIntl();
  return (
    <Helmet>
      <title>{intl.formatMessage({ id: 'HEAD.TITLE' })}</title>
    </Helmet>
  );
};

export { Head };

import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Wrapper,
  EmailNotFound,
  StyledButton,
  NeedInfo,
  StyledP,
  StyledImg,
} from './WrongAccount.style';
import backgroundImg from '../../assets/images/wrong_account/wrong_account.svg';

const WrongAccount = () => {
  const intl = useIntl();
  const history = useHistory();
  const [isFetching] = useState(false);
  const location = useLocation();

  const redirectToHomePage = () => {
    history.push(`/${location.search}`, {});
  };

  return (
    <Wrapper>
      <EmailNotFound className="vtmn-typo_title-3">
        {intl.formatMessage({ id: 'PAGES.WRONG_ACCOUNT.NOT_MY_ACCOUNT' })}
      </EmailNotFound>

      <StyledButton
        className="vtmn-btn"
        onClick={redirectToHomePage}
        disabled={isFetching}
        id="wrong_account_home"
      >
        {intl.formatMessage({ id: 'PAGES.WRONG_ACCOUNT.ANOTHER_MAIL' })}
      </StyledButton>

      <NeedInfo className="vtmn-typo_title-5">
        {intl.formatMessage({ id: 'PAGES.WRONG_ACCOUNT.NEED_INFO' })}
      </NeedInfo>

      <StyledP>{intl.formatMessage({ id: 'PAGES.WRONG_ACCOUNT.DESCRIPTION' })}</StyledP>
      <StyledImg src={backgroundImg}></StyledImg>
    </Wrapper>
  );
};

export default WrongAccount;

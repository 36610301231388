import styled from 'styled-components';

const Title = styled.h1`
  margin: 0;
  font-family: var(--vtmn-typo_font-family);
  font-weight: var(--vtmn-typo_font-weight--bold);
  font-size: 1.75rem; //28px
  line-height: 2rem; //32px
  letter-spacing: 0;
  color: var(--vtmn-color_black);
  overflow-wrap: break-word;

  @media (max-width: 380px) {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
`;

export default Title;

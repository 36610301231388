import styled from 'styled-components';
import Div100vh from 'react-div-100vh';

const Wrapper = styled(Div100vh)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 650px;
`;

const StyledTitle = styled.h4`
  margin-top: 1.5rem;
  text-align: center;
`;

const StyledP = styled.p`
  margin: 1.25rem 0;
`;

const StyledButton = styled.button`
  margin: 1.5rem;
  width: calc(100% - 3rem);
`;

const ConsentTitle = styled.p`
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  color: var(--vtmn-new-color-rock-900);
  font-weight: var(--vtmn-typo_font-weight--bold);
`;

const ConsentWrapper = styled.div``;

const ConsentsWrapper = styled.div`
  padding: 0 1.5rem;
`;

const AcceptAllWrapper = styled.div`
  width: fit-content;
  margin: 0.25rem 0 1rem;
  padding: 0.5rem 0.5rem 0.25rem;

  & label {
    font-size: var(--vtmn-typo_text-3-font-size) !important;
    line-height: var(--vtmn-typo_text-3-line-height) !important;
    align-items: center !important;
    font-weight: 500;
    color: var(--vtmn-color_brand-digital-dark-1);
  }
`;

export {
  Wrapper,
  StyledTitle,
  StyledP,
  StyledButton,
  ConsentTitle,
  ConsentWrapper,
  ConsentsWrapper,
  AcceptAllWrapper,
};

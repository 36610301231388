import styled from 'styled-components';
import Div100vhWithHeader from '../../components/Div100WithHeader';

const Wrapper = styled(Div100vhWithHeader)`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled.h3`
  margin-top: 0.5rem;
`;

const StyledSubTitle = styled.h5`
  margin-top: 1.5rem;
`;

const StyledP = styled.p`
  margin-top: 0.5rem;
`;

const StyledLink = styled.p`
  margin-top: 2rem;

  & > span {
    cursor: pointer;
    font-weight: var(--vtmn-typo_font-weight--bold);
    &:before {
      content: ' ';
    }
  }
`;
const StyledSuccess = styled.p`
  margin-top: 2rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--vtmn-color_success);
`;

export { Wrapper, StyledTitle, StyledSubTitle, StyledP, StyledLink, StyledSuccess };

import styled from 'styled-components';
import Div100vhWithHeader from '../../components/Div100WithHeader';

const Wrapper = styled(Div100vhWithHeader)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

const StyledTitle = styled.h5`
  margin-top: 1.5rem;
`;

const Info = styled.p`
  margin-top: 1rem;
`;

const ScanButton = styled.button`
  margin: 3rem auto 0;
  min-height: calc(88px + 0.875rem);
`;

const ScanText = styled.p`
  margin-top: 1rem;
  text-align: center;
  color: var(--vtmn-color_brand-digital-dark-1);
  font-weight: var(--vtmn-typo_font-weight--bold);
`;

const InputWrapper = styled.div`
  margin-top: 3rem;

  & > div,
  input {
    width: 100%;
  }
`;

const Submit = styled.button`
  display: block;
  margin: 1rem auto;
`;

export { Wrapper, StyledTitle, Info, InputWrapper, ScanButton, Submit, ScanText };

import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  Wrapper,
  LowercaseUserInfo,
  StyledButton,
  UserInfoWrapper,
  CapitalizeUserInfo,
  UserInfoLabel,
  StyledIcon,
  UserInfo,
  StyledTitle,
} from './ExistingAccount.style';

const ExistingAccount = () => {
  const intl = useIntl();
  const location = useLocation<any>();
  const history = useHistory<any>();

  const handleReceiveMyCardByEmail = async () => {
    const path =
      location.state.club_consent_required && !location.state.club_consent ? '/club' : '/user-card';
    history.push(`${path}${location.search}`, {
      email: location.state.email,
      uuid: location.state.uuid,
      cardNumber: location.state.card_number,
    });
  };

  const redirectToWrongAccount = () => {
    history.push(`/wrong-account${location.search}`, {
      email: location.state.email,
    });
  };

  return (
    <Wrapper>
      <StyledTitle className="vtmn-typo_title-3">
        {intl.formatMessage({ id: 'PAGES.EXISTING_ACCOUNT.EMAIL_ALREADY_USED' })}
      </StyledTitle>
      <StyledIcon aria-hidden="true" className="vtmx-user-line" />
      <UserInfoWrapper>
        <LowercaseUserInfo>
          <UserInfoLabel>
            {intl.formatMessage({ id: 'PAGES.EXISTING_ACCOUNT.EMAIL' })}
          </UserInfoLabel>
          <UserInfo>{location.state.email}</UserInfo>
        </LowercaseUserInfo>
        <CapitalizeUserInfo>
          <UserInfoLabel>
            {intl.formatMessage({ id: 'PAGES.EXISTING_ACCOUNT.LAST_NAME' })}
          </UserInfoLabel>
          <UserInfo>
            {location.state.last_name
              ? location.state.last_name
              : intl.formatMessage({ id: 'PAGES.EXISTING_ACCOUNT.EMPTY' })}
          </UserInfo>
        </CapitalizeUserInfo>
        <CapitalizeUserInfo>
          <UserInfoLabel>
            {intl.formatMessage({ id: 'PAGES.EXISTING_ACCOUNT.FIRST_NAME' })}
          </UserInfoLabel>
          <UserInfo>
            {location.state.first_name
              ? location.state.first_name
              : intl.formatMessage({ id: 'PAGES.EXISTING_ACCOUNT.EMPTY' })}
          </UserInfo>
        </CapitalizeUserInfo>
        <LowercaseUserInfo>
          <UserInfoLabel>
            {intl.formatMessage({ id: 'PAGES.EXISTING_ACCOUNT.MOBILE' })}
          </UserInfoLabel>
          <UserInfo>
            {location.state.mobile
              ? location.state.mobile
              : intl.formatMessage({ id: 'PAGES.EXISTING_ACCOUNT.EMPTY' })}
          </UserInfo>
        </LowercaseUserInfo>
      </UserInfoWrapper>
      <StyledButton
        className="vtmn-btn"
        onClick={handleReceiveMyCardByEmail}
        id="existing_account_confirm"
      >
        {intl.formatMessage({ id: 'PAGES.EXISTING_ACCOUNT.CONFIRM_ACCOUNT' })}
      </StyledButton>
      <StyledButton
        className="vtmn-btn vtmn-btn_variant--ghost"
        onClick={redirectToWrongAccount}
        id="existing_account_wrong"
      >
        {intl.formatMessage({ id: 'PAGES.EXISTING_ACCOUNT.NOT_MY_ACCOUNT' })}
      </StyledButton>
    </Wrapper>
  );
};

export default ExistingAccount;

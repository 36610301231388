import styled from 'styled-components';
import Div100vhWithHeader from '../../components/Div100WithHeader';

const Wrapper = styled(Div100vhWithHeader)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

const StyledTitle = styled.h3`
  text-align: center;
`;

const StyledAdvantages = styled.div`
  margin-top: 1rem;
  text-align: center;
`;

const StyledP = styled.p`
  margin-top: 2rem;

  span {
    font-weight: var(--vtmn-typo_font-weight--bold);
  }
`;

const StyledButton = styled.button`
  width: 100%;
  margin-top: 1.375rem;
`;

const StyledInput = styled.div`
  margin-top: 1rem;

  input {
    width: 100%;
  }
`;

const RecaptchaFooterP = styled.div`
  margin-top: auto;
  padding: 1rem;
  font-size: 0.6875rem;
  line-height: 1rem;
  letter-spacing: 0;
  text-align: center;
  color: var(--vtmn-color_grey-dark-4);

  a {
    color: var(--vtmn-color_grey-dark-4);
  }
`;

export {
  Wrapper,
  StyledTitle,
  StyledP,
  StyledButton,
  StyledAdvantages,
  StyledInput,
  RecaptchaFooterP,
};

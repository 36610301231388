import Div100vh from 'react-div-100vh';
import styled from 'styled-components';

const Wrapper = styled(Div100vh)`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 650px;
  background-color: var(--vtmn-color_black);

  & video {
    object-fit: cover;
  }
`;

const HeaderWrapper = styled.div`
  z-index: 1000;
  position: absolute;
  top: 1.5rem;
  left: 1.25rem;
  right: 1.25rem;
  display: flex;
  align-items: center;

  & button {
    position: absolute;
    width: 1.5rem !important;
    height: 1.5rem !important;
    min-height: 1.5rem !important;
  }

  & img {
    margin: 0 auto;
    height: 32px;
  }

  & span {
    font-size: 1.5rem !important;
    transform: translate(-0.5rem, -0.5rem) !important;
  }
`;

const CenterImg = styled.img`
  z-index: 1000;
  position: absolute;
  top: 13.5rem;
  left: 0;
  right: 0;
  margin: auto;
`;

const CodeWrapper = styled.div`
  position: absolute;
  bottom: 3rem;
  left: 1.5rem;
  right: 1.5rem;
  z-index: 1000;
  background-color: var(--vtmn-color_white);
  box-shadow: 0px 4px 6px rgba(0, 83, 125, 0.07);
  border-radius: 1rem;
  padding: 1.25rem;
  display: flex;
  align-items: center;
`;

const CodeTextWrapper = styled.div`
  margin-left: 1rem;
`;

const CodeTitleWrapper = styled.p`
  font-weight: var(--vtmn-typo_font-weight--bold);
`;

const ModalTitle = styled.p`
  font-weight: var(--vtmn-typo_font-weight--bold);
`;

const ModalParagraph = styled.p`
  margin-top: 0.75rem;
`;

const ModalSelect = styled.div`
  margin-top: 0.75rem;
  width: 100%;

  & > select {
    width: inherit;
  }
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-top: 1.5rem;

  &.single > button {
    margin: 0 auto;
  }
`;

const NeedHelpWrapper = styled.div`
  z-index: 1000;
  position: absolute;
  top: 7.875rem;
  left: 0;
  right: 0;

  & > button {
    display: block;
    margin: 0 auto;
  }
`;

const ScanZone = styled.img`
  z-index: 1000;
`;

export {
  Wrapper,
  CodeWrapper,
  CodeTextWrapper,
  HeaderWrapper,
  CenterImg,
  CodeTitleWrapper,
  ModalTitle,
  ModalParagraph,
  ModalButtons,
  NeedHelpWrapper,
  ModalSelect,
  ScanZone,
};

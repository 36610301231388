/** @module services/apis/connect */

import { client } from '../../client';

interface LookupParams {
  email?: string;
  card_number?: string;
  store: string | number;
  country_code: string;
}

/**
 * Lookup for an existing account.
 * @param {object} userInput - The lookup input.
 * @param {object} config - The current app configuration.
 * @param {string} recaptchaToken - The Google reCaptcha token.
 * @returns {Promise<boolean>} - Resolves true if there is an existing account,
 */
const lookup = (params: LookupParams, config: any, recaptchaToken: any) => {
  const url = `${config.APIS__CONNECT__ENDPOINT}/login_store/lookup?client_id=${config.CLIENT_ID}`;

  if (!params.store || !params.country_code) {
    return Promise.reject(new Error('Missing country code or store'));
  }

  return client(recaptchaToken ? `${url}&responseCaptcha=${recaptchaToken}` : url, {
    headers: {
      'x-api-key': config.APIS__CONNECT__API_KEY,
    },
    body: params,
  })
    .then((res) => {
      switch (res.status) {
        case 200:
          return Promise.resolve(res);
        case 204:
          return Promise.resolve(false);
        case 401:
          return Promise.reject('INVALID_RECAPTCHA');
        default:
          return Promise.reject();
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export interface SignupPurpose {
  id: string;
  enabled: boolean;
}

interface SignupParams {
  email: string;
  list_purposes: SignupPurpose[];
  country: string;
  store: string | number;
  club_consent: boolean;
}
/**
 * Sign up quickly without password.
 * @param {object} params - body.
 * @param {object} config - The current app configuration.
 * @param {object} language - The current language.
 * @param {string} recaptchaToken - The Google reCaptcha token.
 * @returns {Promise<object>} - Resolves the account created,
 */
const signup = (
  params: SignupParams,
  config: any,
  language: string,
  recaptchaToken: string
): Promise<any> => {
  const url = `${config.APIS__CONNECT__ENDPOINT}/login_store/signup?client_id=${config.CLIENT_ID}`;

  if (!params.store || !params.country) {
    return Promise.reject(new Error('Missing country code or store'));
  }

  return client(recaptchaToken ? `${url}&responseCaptcha=${recaptchaToken}` : url, {
    headers: {
      'x-api-key': config.APIS__CONNECT__API_KEY,
    },
    body: {
      ...params,
      language: language.split('-')[0] || language,
    },
  })
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return res;
      }
      return Promise.reject(res.statusText);
    })
    .then((data) => Promise.resolve(data))
    .catch((err) => Promise.reject(err));
};

/**
 * Send the loyalty card by email.
 * @param {string} userInput - The user input.
 * @param {object} config - The current app configuration.
 * @param {object} language - The current language.
 * @param {string} recaptchaToken - The Google reCaptcha token.
 * @returns {Promise<boolean>} - Resolves true if there is an existing account,
 */
const sendLoyaltyCardByEmail = (
  { email, uuid, country, store }: { email: string; uuid: string; country: string; store: string },
  config: any,
  language: any,
  recaptchaToken: string
): Promise<boolean> => {
  const url = `${config.APIS__CONNECT__ENDPOINT}/login_store/send_card_to_email?client_id=${config.CLIENT_ID}`;

  return client(recaptchaToken ? `${url}&responseCaptcha=${recaptchaToken}` : url, {
    headers: {
      'x-api-key': config.APIS__CONNECT__API_KEY,
    },
    body: {
      email,
      uuid,
      country,
      language: language.split('-')[0] || language,
      store,
    },
  })
    .then((res) => Promise.resolve(res.status === 200))
    .catch((err) => Promise.reject(err));
};

const getConsentPurposes = (config: any): Promise<any> => {
  const country = new URLSearchParams(window.location.search).get('country');
  const url = `${config.APIS__CONNECT__ENDPOINT}/login_store/consent_purposes?country_code=${country}`;

  return client(url, {
    headers: {
      'x-api-key': config.APIS__CONNECT__API_KEY,
    },
  })
    .then((res) => Promise.resolve(res))
    .catch((err) => Promise.reject(err));
};

const sendClubConsentMail = (payload: any, config: any) => {
  const url = `${config.APIS__CONNECT__ENDPOINT}/login_store/club_consent_send_email?client-id=${config.CLIENT_ID}`;

  return client(url, {
    headers: {
      'x-api-key': config.APIS__CONNECT__API_KEY,
    },
    body: {
      ...payload,
    },
  })
    .then((res) => Promise.resolve(res.status === 200))
    .catch((err) => Promise.reject(err));
};

const clubConsentValidation = (token: string, config: any) => {
  const url = `${config.APIS__CONNECT__ENDPOINT}/login_store/club_consent_validation`;

  return client(url, {
    headers: {
      'x-api-key': config.APIS__CONNECT__API_KEY,
    },
    body: {
      token,
    },
  })
    .then((res) => Promise.resolve(res))
    .catch((err) => Promise.reject(err));
};

const addToWallet = (payload: any, config: any) => {
  const url = `${config.APIS__CONNECT__ENDPOINT}/login_store/add_to_wallet_send_email`;

  return client(url, {
    headers: {
      'x-api-key': config.APIS__CONNECT__API_KEY,
    },
    body: payload,
  })
    .then((res) => Promise.resolve(res))
    .catch((err) => Promise.reject(err));
};

const addToWalletEnabled = (config: any) => {
  const country = new URLSearchParams(window.location.search).get('country');
  const url = `${config.APIS__CONNECT__ENDPOINT}/login_store/add_to_wallet_enabled/${country}`;

  return client(url, {
    headers: {
      'x-api-key': config.APIS__CONNECT__API_KEY,
    },
  })
    .then((res) => Promise.resolve(res))
    .catch((err) => Promise.reject(err));
};

export {
  lookup,
  signup,
  sendLoyaltyCardByEmail,
  getConsentPurposes,
  sendClubConsentMail,
  clubConsentValidation,
  addToWallet,
  addToWalletEnabled,
};

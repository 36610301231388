import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import dktCard from '../../assets/images/card/dkt-card.png';
import {
  Header,
  InfoWrapper,
  Wrapper,
  Info,
  HeaderIcons,
  StyledLink,
  StyledSuccess,
  StyledCard,
} from './UserCardSave.style';
import cardLine from '../../assets/images/card/card-line.svg';
import walletLine from '../../assets/images/card/wallet-line.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { ConfigContext } from '../../contexts/Config';
import { useQuery } from '../../hooks/useQuery';
import { addToWallet } from '../../services/apis/connect';

const UserCardSave = () => {
  const intl = useIntl();
  const location = useLocation<any>();
  const history = useHistory();
  const query = useQuery();
  const { config } = useContext(ConfigContext);
  const [showLink, setShowLink] = useState(true);
  const cardMailSent = location.state.cardMailSent;

  const sendMail = useCallback(() => {
    const payload = {
      client_id: config.CAPTAIN_WALLET_CLIENT_ID,
      country_code: query.get('country'),
      email: location.state.email,
      navigation_store: parseInt(query.get('store') as string),
      uuid: location.state.uuid,
    };
    addToWallet(payload, config).then(() => showLinkOnSuccess());
  }, [location, config, query]);

  useEffect(() => {
    if (!cardMailSent) {
      sendMail();
      history.replace(`/user-card-save${location.search}`, {
        ...location.state,
        cardMailSent: true,
      });
    }
  }, [sendMail, cardMailSent, history, location]);

  const showLinkOnSuccess = () => {
    setShowLink(false);
    const timer = setTimeout(() => setShowLink(true), 60000);
    return () => clearTimeout(timer);
  };

  return (
    <Wrapper>
      <Header>
        <h3 className="vtmn-typo_title-3">
          {intl.formatMessage({ id: 'PAGES.USER_CARD_SAVE.TITLE' })}
        </h3>
        <HeaderIcons>
          <img alt="" src={cardLine} />
          <span className="vtmx-arrow-right-line"></span>
          <img alt="" src={walletLine} />
        </HeaderIcons>
      </Header>
      <StyledCard alt="" src={dktCard} />
      <InfoWrapper>
        <h5 className="vtmn-typo_title-5">
          {intl.formatMessage({ id: 'PAGES.USER_CARD_SAVE.SUBTITLE' })}
        </h5>
        <Info className="vtmn-typo_text-2">
          {intl.formatMessage({ id: 'PAGES.USER_CARD_SAVE.INFO' })}
        </Info>
        {showLink && (
          <StyledLink className="vtmn-typo_text-3">
            {intl.formatMessage({ id: 'PAGES.CLUB_MAIL.MAIL_NOT_SENT' })}
            <span
              className="vtmn-link vtmn-link--standalone vtmn-link_size--small"
              id="club_mail_send"
              onClick={sendMail}
            >
              {intl.formatMessage({ id: 'PAGES.CLUB_MAIL.SEND_NEW_MAIL' })}
            </span>
          </StyledLink>
        )}
        {!showLink && (
          <StyledSuccess className="vtmn-typo_text-3">
            {intl.formatMessage({ id: 'PAGES.CLUB_MAIL.MAIL_SENT' })}
            <span> {location.state.email}</span>
          </StyledSuccess>
        )}
      </InfoWrapper>
    </Wrapper>
  );
};

export default UserCardSave;

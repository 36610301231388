import React, { useContext, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteProps,
  Switch,
  useLocation,
} from 'react-router-dom';
import ReactGA from 'react-ga';
import { SpinnerWrapper } from './components/Spinner/Spinner.style';
import Spinner from './components/Spinner';
import SignUp from './pages/SignUp';
import ExistingAccount from './pages/ExistingAccount';
import Consent from './pages/Consent';
import WrongAccount from './pages/WrongAccount';
import UserCard from './pages/UserCard';
import UserCardSave from './pages/UserCardSave';
import LangChoice from './pages/LangChoice';
import { ConfigContext } from './contexts/Config';
import { LocaleContext } from './contexts/Locale';
import { Head } from './Head';
import Header from './components/Header';
import Club from './pages/Club';
import { getLangDir } from 'rtl-detect';
import { injectGoogleReCaptchaScript } from './services/recaptcha-enterprise';
import ClubMail from './pages/ClubMail';
import InvalidClubMail from './pages/InvalidClubMail';
import ClubMailValid from './pages/ClubMailValid';
import CardSignUp from './pages/CardSignUp';
import ExistingCardAccount from './pages/ExistingCardAccount';
import ScanCard from './pages/ScanCard';

export const withTracker = (WrappedComponent: any, options = {}) => {
  const trackPage = (page: any) => {
    ReactGA.set({
      page,
      ...options,
    });
    ReactGA.pageview(page);
  };

  const HOC = (props: any) => {
    useEffect(() => trackPage(props.location.pathname), [props.location.pathname]);

    return <WrappedComponent {...props} />;
  };

  return HOC;
};

const ProtectedRoute = ({
  component: Component,
  ...rest
}: { component: React.ComponentType<any> } & Partial<RouteProps>) => {
  const location = useLocation();
  return (
    <Route
      {...rest}
      render={(props: any) => {
        return location && location.state ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', search: location.search }} />
        );
      }}
    />
  );
};

const DisplayHeader = () => {
  const location = useLocation();
  return location.pathname !== '/lang-choice' &&
    location.pathname !== '/club-email-valid' &&
    location.pathname !== '/scan-card' ? (
    <Header />
  ) : null;
};

const renderRouter = () => (
  <Router>
    <DisplayHeader />
    <Switch>
      <ProtectedRoute path="/existing-account" component={withTracker(ExistingAccount)} />
      <ProtectedRoute path="/existing-card-account" component={withTracker(ExistingCardAccount)} />
      <ProtectedRoute path="/consent" component={withTracker(Consent)} />
      <ProtectedRoute path="/wrong-account" component={withTracker(WrongAccount)} />
      <ProtectedRoute path="/user-card" component={withTracker(UserCard)} />
      <ProtectedRoute path="/user-card-save" component={withTracker(UserCardSave)} />
      <ProtectedRoute path="/club" component={withTracker(Club)} />
      <ProtectedRoute path="/club-mail" component={withTracker(ClubMail)} />
      <ProtectedRoute path="/invalid-club-mail" component={withTracker(InvalidClubMail)} />
      <Route path="/club-email-valid" component={withTracker(ClubMailValid)} />
      <Route path="/lang-choice" component={withTracker(LangChoice)} />
      <Route path="/scan-card" component={withTracker(ScanCard)} />
      <Route path="/card" component={withTracker(CardSignUp)} />
      <Route path="/" component={withTracker(SignUp)} />
    </Switch>
  </Router>
);

const App = (): JSX.Element => {
  const { config, isConfigLoading } = useContext(ConfigContext);
  const { language, messages, areMessagesLoading } = useContext(LocaleContext);

  useEffect(() => {
    if (config) {
      ReactGA.initialize(config.GA_TRACKING_ID);
      if (JSON.parse(config.GOOGLE_RECAPTCHA_ACTIVE)) {
        injectGoogleReCaptchaScript(config.GOOGLE_RECAPTCHA_ENTERPRISE_KEY);
      }
    }
  }, [config]);

  useEffect(() => {
    const element = document.getElementById('root');
    if (element instanceof HTMLElement) {
      element.style.direction = getLangDir(language as string);
    }
  });

  return !areMessagesLoading && !isConfigLoading ? (
    <IntlProvider key={language} locale={language as string} messages={messages}>
      <Head />
      {renderRouter()}
    </IntlProvider>
  ) : (
    <SpinnerWrapper>
      <Spinner />
    </SpinnerWrapper>
  );
};

export default App;

import React from 'react';
import ReactGA from 'react-ga';
import AliceCarousel from 'react-alice-carousel';
import { Description, StyledImage } from './Advantages.style';

const Advantages = (props: any) => {
  const handleOnSlideChange = () => {
    ReactGA.event({
      category: 'signup',
      action: 'advantages',
      label: 'swipe',
    });
  };

  const mapItems = (advantages: any) =>
    advantages.map((advantage: any, index: number) => (
      <React.Fragment key={index}>
        <StyledImage src={advantage.iconUrl} alt={advantage.description} noLazyLoad />
        <Description>{advantage.description}</Description>
      </React.Fragment>
    ));

  const items = mapItems(props.advantages);

  return items.length ? (
    <AliceCarousel
      mouseTrackingEnabled
      buttonsDisabled
      onSlideChange={handleOnSlideChange}
      autoPlay
      autoPlayInterval={2000}
      items={items}
    />
  ) : null;
};

export default Advantages;

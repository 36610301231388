import React from 'react';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import decathlonLogo from '../../assets/images/decathlon-logo.svg';
import { Wrapper, StyledImage } from './Header.style';

const Header = (): JSX.Element => {
  const intl = useIntl();
  const location = useLocation();

  return (
    <Wrapper>
      <Link to={{ pathname: '/', search: location.search }}>
        <StyledImage
          src={decathlonLogo}
          alt={intl.formatMessage({ id: 'COMPONENTS.HEADER.LOGO_ALT' })}
        />
      </Link>
    </Wrapper>
  );
};

export default Header;

import styled from 'styled-components';
import Div100vhWithHeader from '../../components/Div100WithHeader';

const Wrapper = styled(Div100vhWithHeader)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

const StyledTitle = styled.h3`
  margin-top: 1.5rem;
  text-align: center;
`;

const UserInfoWrapper = styled.div`
  margin-bottom: 2rem;
`;

const LowercaseUserInfo = styled.div`
  margin: 0;
  font-weight: var(--vtmn-typo_font-weight--regular);
  text-transform: lowercase;
`;

const CapitalizeUserInfo = styled.div`
  margin: 0;
  font-weight: var(--vtmn-typo_font-weight--regular);
  text-transform: capitalize;
`;

const UserInfoLabel = styled.label`
  font-weight: var(--vtmn-typo_font-weight--bold);
  text-transform: capitalize;

  &:after {
    content: ' : ';
  }
`;

const UserInfo = styled.p`
  display: inline-block;
  margin: 0;
`;

const StyledButton = styled.button`
  width: 100%;
  margin-top: 1.375rem;
`;

const StyledIcon = styled.span`
  border-radius: 50%;
  background-color: var(--vtmn-new-color-cobalt-600);
  width: 48px;
  height: 48px;
  line-height: 48px;
  margin: 2rem auto;
  padding: 0.75rem;
  font-size: 1.5rem;
  color: var(--vtmn-semantic-color_content-accent);
`;

export {
  Wrapper,
  LowercaseUserInfo,
  StyledButton,
  UserInfoWrapper,
  CapitalizeUserInfo,
  UserInfoLabel,
  StyledIcon,
  UserInfo,
  StyledTitle,
};

import React, { useState, useEffect } from 'react';
import { client } from '../../services/client';

export type Config = {
  isConfigLoading: boolean;
  config: any;
};

const ConfigContext = React.createContext<Partial<Config>>({});

const ConfigProvider = ({ children }: JSX.ElementChildrenAttribute): JSX.Element => {
  const [config, setConfig] = useState(null);
  const [isConfigLoading, setIsConfigLoading] = useState(true);

  useEffect(() => {
    if (!config) {
      setIsConfigLoading(true);
      client(`data/config.json`, {
        headers: {
          Accept: 'application/json',
        },
      }).then(({ data }) => {
        setConfig({
          ...data,
        });
      });
    }
  }, [config]);

  useEffect(() => {
    if (config) {
      setIsConfigLoading(false);
    }
  }, [config]);

  return (
    <ConfigContext.Provider value={{ isConfigLoading, config }}>{children}</ConfigContext.Provider>
  );
};

export { ConfigContext, ConfigProvider };

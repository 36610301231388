import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Wrapper,
  StyledTitle,
  UserInfoWrapper,
  CapitalizeUserInfo,
  UserInfo,
  UserInfoLabel,
  LowercaseUserInfo,
  InfoText,
  EmailWrapper,
  ButtonsWrapper,
  TopButtonsWrapper,
  BottomButton,
  ValidIcon,
  InvalidIcon,
  AskWrapper,
} from './ExistingCardAccount.style';

const ExistingCardAccount = () => {
  const intl = useIntl();
  const location = useLocation<any>();
  const history = useHistory();

  const validAccount = () => {
    const path =
      location.state.club_consent_required && !location.state.club_consent ? '/club' : '/user-card';
    history.push(`${path}${location.search}`, {
      uuid: location.state.uuid,
      cardNumber: location.state.card_number,
      email: null,
    });
  };

  const wrongAccount = () => {
    history.push(`/wrong-account${location.search}`, {
      card_number: location.state.card_number,
    });
  };

  return (
    <Wrapper>
      <StyledTitle className="vtmn-typo_title-5">
        {intl.formatMessage({ id: 'PAGES.EXISTING_CARD_ACCOUNT.TITLE' })}
      </StyledTitle>
      <UserInfoWrapper>
        <CapitalizeUserInfo>
          <UserInfoLabel>
            {intl.formatMessage({ id: 'PAGES.EXISTING_CARD_ACCOUNT.LAST_NAME' })}
          </UserInfoLabel>
          <UserInfo id="existing_card_account.last_name">
            {location.state.last_name
              ? location.state.last_name
              : intl.formatMessage({ id: 'PAGES.EXISTING_CARD_ACCOUNT.EMPTY' })}
          </UserInfo>
        </CapitalizeUserInfo>
        <CapitalizeUserInfo>
          <UserInfoLabel>
            {intl.formatMessage({ id: 'PAGES.EXISTING_CARD_ACCOUNT.FIRST_NAME' })}
          </UserInfoLabel>
          <UserInfo id="existing_card_account.first_name">
            {location.state.first_name
              ? location.state.first_name
              : intl.formatMessage({ id: 'PAGES.EXISTING_CARD_ACCOUNT.EMPTY' })}
          </UserInfo>
        </CapitalizeUserInfo>
        <LowercaseUserInfo>
          <UserInfoLabel>
            {intl.formatMessage({ id: 'PAGES.EXISTING_CARD_ACCOUNT.CARD_NUMBER' })}
          </UserInfoLabel>
          <UserInfo id="existing_card_account.card_number">{location.state.card_number}</UserInfo>
        </LowercaseUserInfo>
        <LowercaseUserInfo>
          <UserInfoLabel>
            {intl.formatMessage({ id: 'PAGES.EXISTING_CARD_ACCOUNT.MOBILE' })}
          </UserInfoLabel>
          <UserInfo id="existing_card_account.mobile">
            {location.state.mobile
              ? location.state.mobile
              : intl.formatMessage({ id: 'PAGES.EXISTING_CARD_ACCOUNT.EMPTY' })}
          </UserInfo>
        </LowercaseUserInfo>
      </UserInfoWrapper>
      <InfoText className="vtmn-typo_text-3">
        {intl.formatMessage({ id: 'PAGES.EXISTING_CARD_ACCOUNT.INFO' })}
      </InfoText>
      <EmailWrapper>
        <UserInfoLabel>
          {intl.formatMessage({ id: 'PAGES.EXISTING_CARD_ACCOUNT.EMAIL' })}
        </UserInfoLabel>
        <UserInfo id="existing_card_account.email">{location.state.email}</UserInfo>
      </EmailWrapper>
      <AskWrapper>
        {intl.formatMessage({ id: 'PAGES.EXISTING_CARD_ACCOUNT.GOOD_EMAIL' })}
      </AskWrapper>
      <ButtonsWrapper>
        <TopButtonsWrapper>
          <button
            id="existing_card_account.valid_account"
            className="vtmn-btn vtmn-btn_variant--secondary vtmn-btn_size--small"
            onClick={validAccount}
          >
            <ValidIcon className="vtmx-checkbox-circle-fill"></ValidIcon>
            <p
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: 'PAGES.EXISTING_CARD_ACCOUNT.VALID_ACCOUNT' }),
              }}
            ></p>
          </button>
          <button
            id="existing_card_account.invalid_account"
            className="vtmn-btn vtmn-btn_variant--secondary vtmn-btn_size--small"
            onClick={wrongAccount}
          >
            <InvalidIcon className="vtmx-close-circle-fill"></InvalidIcon>
            <p
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: 'PAGES.EXISTING_CARD_ACCOUNT.INVALID_ACCOUNT' }),
              }}
            ></p>
          </button>
        </TopButtonsWrapper>
        <BottomButton
          id="existing_card_account.invalid_info"
          className="vtmn-btn vtmn-btn_variant--secondary vtmn-btn_size--small"
        >
          <span className="vtmx-edit-line"></span>
          <p
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: 'PAGES.EXISTING_CARD_ACCOUNT.INVALID_INFO' }),
            }}
          ></p>
        </BottomButton>
      </ButtonsWrapper>
    </Wrapper>
  );
};

export default ExistingCardAccount;

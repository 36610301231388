import React from 'react';
import ReactDOM from 'react-dom';
import { ToastProvider } from 'react-toast-notifications';
import './assets/styles/vitamin-play-override.css';
import '@fontsource/inter';
import 'react-alice-carousel/lib/alice-carousel.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ConfigProvider } from './contexts/Config';
import { LocaleProvider } from './contexts/Locale';
// Import vtmn components
import '@vtmn/css-checkbox/dist/index.css';
import '@vtmn/css-link/dist/index.css';
import '@vtmn/css-text-input/dist/index.css';
import '@vtmn/css-button/dist/index.css';
import '@vtmn/icons/dist/vitamix/font/vitamix.css';
import '@vtmn/css/dist/index.css';

ReactDOM.render(
  <LocaleProvider>
    <ConfigProvider>
      <ToastProvider placement="bottom-center">
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </ToastProvider>
    </ConfigProvider>
  </LocaleProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

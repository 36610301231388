import styled from 'styled-components';
import Image from 'react-graceful-image';

const LightGreyCircle = styled.div`
  border-radius: 50%;
  width: 164px;
  height: 164px;
  background-color: var(--grey-2);
  margin: 26px auto;
  position: absolute;
  left: 0;
  right: 0;
`;

const Description = styled.h3`
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  font-weight: normal;
  margin: 16px 0;
  color: var(--grey-4);

  @media (max-height: 650px) {
    margin-block-start: 0;
    margin-block-end: 0;
  }
`;

const StyledImage = styled(Image)`
  width: 15vh;
  height: 15vh;
  object-fit: contain;
  margin: auto;
`;

export { LightGreyCircle, Description, StyledImage };

import axios from 'axios';

const DEFAULT_HEADERS = { 'content-type': 'application/json' };

const client = (url: string, { body, ...customConfig }: any = {}): Promise<any> => {
  const options = {
    method: customConfig.method || body ? 'POST' : 'GET',
    url,
    ...customConfig,
    headers: {
      ...DEFAULT_HEADERS,
      ...customConfig.headers,
      'X-Selected-Country': new URLSearchParams(window.location.search).get('country'),
    },
  };

  if (body) {
    options.data = JSON.parse(JSON.stringify(body));
  }

  const onSuccess = (response: any) => response;

  const onError = ({ response, message, config }: any) => {
    console.error('Request Failed:', config);

    if (response) {
      console.error('Status:', response.status);
      console.error('Data:', response.data);
      console.error('Headers:', response.headers);
    } else {
      console.error('Error Message:', message);
    }

    return Promise.reject(response || message);
  };

  return axios(options).then(onSuccess).catch(onError);
};

export { DEFAULT_HEADERS, client };

import React from 'react';
import { Wrapper } from './RequiredConsentCard.style';

const RequiredConsentCard = (props: any): JSX.Element => {
  const changeEvent = () => {
    props.onChange(props.id);
  };
  return (
    <Wrapper>
      <input
        className="vtmn-checkbox"
        type="checkbox"
        id={props.id}
        checked={props.checked}
        onChange={changeEvent}
      />
      <label htmlFor={props.id}>{props.label}</label>
    </Wrapper>
  );
};

export default RequiredConsentCard;

import React, { useContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useIntl } from 'react-intl';
import { useToasts } from 'react-toast-notifications';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  RecaptchaFooterP,
  StyledTitle,
  StyledButton,
  StyledInput,
  StyledP,
  Wrapper,
  StyledAdvantages,
} from './SignUp.style';
import { isEmail } from '../../services/inputs';
import { ConfigContext } from '../../contexts/Config';
import { lookup, getConsentPurposes, signup } from '../../services/apis/connect';
import Advantages from './Advantages';
import { LocaleContext } from '../../contexts/Locale';
import { useQuery } from '../../hooks/useQuery';
import ReactHtmlParser from 'react-html-parser';
import { executeRecaptcha } from '../../services/recaptcha-enterprise';

const SignUp = () => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const NUMBER_OF_ADVANTAGES = 11;
  const { language } = useContext(LocaleContext);

  const [isFetching, setIsFetching] = useState(false);
  const [advantages, setAdvantages] = useState<any>([]);

  const { addToast } = useToasts();
  const { config } = useContext(ConfigContext);
  const { defaultCountryLanguageSet } = useContext(LocaleContext);
  const { register, handleSubmit, errors } = useForm();

  const country = new URLSearchParams(window.location.search).get('country');

  const query = useQuery();

  const getRecaptchaToken = () =>
    JSON.parse(config.GOOGLE_RECAPTCHA_ACTIVE)
      ? executeRecaptcha('signUp', config.GOOGLE_RECAPTCHA_ENTERPRISE_KEY)
      : Promise.resolve(null);

  const signupAction = async (data: any) => {
    try {
      const recaptchaToken = await getRecaptchaToken();
      const response = await signup(
        {
          email: data.email,
          list_purposes: [],
          country: query.get('country') as string,
          store: parseInt(query.get('store') as string),
          club_consent: false,
        },
        config,
        language as string,
        recaptchaToken
      );
      history.push(`/user-card${location.search}`, {
        email: data.email,
        uuid: response.data.uuid,
        cardNumber: response.data.card_number,
        signup: true,
      });
    } catch (err) {
      addToast(intl.formatMessage({ id: 'COMMON.ERRORS.DEFAULT' }), {
        appearance: 'error',
      });
      setIsFetching(false);
    }
  };

  const onSubmitValidated = async (data: any) => {
    setIsFetching(true);
    try {
      ReactGA.event({
        category: 'signup',
        action: 'inputEmail',
        label: 'emailOK',
      });
      const recaptchaToken = await getRecaptchaToken();

      const store = parseInt(query.get('store') as string);
      const accountExists = await lookup(
        { email: data.email as string, store, country_code: country as string },
        config,
        recaptchaToken
      );
      if (accountExists) {
        history.push(`/existing-account${location.search}`, accountExists.data);
      } else {
        const consentPurpose = await getConsentPurposes(config);
        if (consentPurpose.data?.club_consent_required) {
          history.push(`/club${location.search}`, {
            ...data,
            consents: consentPurpose.data.login_store_sign_up_consents_response,
            signup: true,
          });
        } else if (consentPurpose?.data?.login_store_sign_up_consents_response?.length > 0) {
          history.push(`/consent${location.search}`, {
            ...data,
            consents: consentPurpose.data.login_store_sign_up_consents_response,
            signup: true,
          });
        } else {
          signupAction(data);
        }
      }
    } catch (err) {
      addToast(intl.formatMessage({ id: 'COMMON.ERRORS.DEFAULT' }), {
        appearance: 'error',
      });

      if (err === 'INVALID_RECAPTCHA') {
        ReactGA.event({
          category: 'signup',
          action: 'inputEmail',
          label: 'invalidRecaptcha',
        });
      }

      ReactGA.event({
        category: 'signup',
        action: 'inputEmail',
        label: 'errorMessage',
      });
      setIsFetching(false);
    }
  };

  useEffect(() => {
    ReactGA.event({
      category: 'signup',
      action: 'inputEmail',
      label: 'errorMessage',
    });
  }, [errors]);

  useEffect(() => {
    const items = [];

    for (let i = 1; i <= NUMBER_OF_ADVANTAGES; i++) {
      if (intl.messages && !!intl.messages[`icons.${i}.iconUrl`]) {
        items.push({
          iconUrl: intl.formatMessage({ id: `icons.${i}.iconUrl` }),
          description: intl.formatMessage({ id: `icons.${i}.description` }),
        });
      }
    }
    setAdvantages(items);
  }, [intl]);

  return defaultCountryLanguageSet ? (
    <Wrapper>
      <StyledTitle className="vtmn-typo_title-3">
        {intl.formatMessage({ id: 'PAGES.SIGNUP.TITLE' })}
      </StyledTitle>
      <StyledAdvantages>
        <Advantages advantages={advantages} />
      </StyledAdvantages>
      <StyledP>{ReactHtmlParser(intl.formatMessage({ id: 'PAGES.SIGNUP.REGISTER_INFO' }))}</StyledP>
      <form onSubmit={handleSubmit(onSubmitValidated)} noValidate>
        <StyledInput>
          <label className="vtmn-text-input_label" htmlFor="signup-input">
            {intl.formatMessage({ id: 'PAGES.SIGNUP.EMAIL_LABEL' })}
          </label>
          <input
            id="signup-input"
            name="email"
            className={`vtmn-text-input ${errors.email ? 'vtmn-text-input--error' : ''}`}
            aria-label={intl.formatMessage({
              id: 'PAGES.SIGNUP.EMAIL_ARIA_LABEL',
            })}
            placeholder={intl.formatMessage({
              id: 'PAGES.SIGNUP.EMAIL_PLACEHOLDER',
            })}
            ref={register({
              required: {
                value: true,
                message: intl.formatMessage({
                  id: 'PAGES.SIGNUP.ERRORS.EMAIL_REQUIRED',
                }),
              },
              validate: (value) =>
                isEmail(value) || intl.formatMessage({ id: 'PAGES.SIGNUP.ERRORS.EMAIL_INVALID' }),
            })}
            type="email"
            autoFocus
          />
          {errors.email && (
            <p className="vtmn-text-input_helper-text vtmn-text-input_helper-text--error">
              {errors.email.message}
            </p>
          )}
        </StyledInput>
        <StyledButton className="vtmn-btn" disabled={isFetching} type="submit" id="signup_submit">
          {intl.formatMessage({ id: 'PAGES.SIGNUP.LETS_GO_BUTTON' })}
        </StyledButton>
      </form>
      {JSON.parse(config.GOOGLE_RECAPTCHA_ACTIVE) && (
        <RecaptchaFooterP>
          {intl.formatMessage(
            {
              id: 'PAGES.SIGNUP.RECAPTCHA_FOOTER.TEXT',
            },
            {
              PRIVARY_POLICY_LINK: (
                <a
                  href="https://policies.google.com/privacy"
                  key="recaptcha-privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage({
                    id: 'PAGES.SIGNUP.RECAPTCHA_FOOTER.PRIVARY_POLICY',
                  })}
                </a>
              ),
              TERMS_OF_SERVICE_LINK: (
                <a
                  href="https://policies.google.com/terms"
                  key="recaptcha-terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage({
                    id: 'PAGES.SIGNUP.RECAPTCHA_FOOTER.TERMS_OF_SERVICE',
                  })}
                </a>
              ),
            }
          )}
        </RecaptchaFooterP>
      )}
    </Wrapper>
  ) : (
    <Redirect to={{ pathname: '/lang-choice', search: location.search }} />
  );
};

export default SignUp;

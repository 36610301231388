import React from 'react';
import { BackgroundWrapper, CardWrapper, TopWrapper } from './Modal.style';
import { Transition } from 'react-transition-group';

const Modal = (props: any): JSX.Element => {
  const duration = 500;

  const defaultBackgroundStyle = {
    transition: `opacity ${duration}ms ease`,
    opacity: 0,
  };

  const backgroundTransitionStyles: any = {
    entering: { opacity: 0.8 },
    entered: { opacity: 0.8 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  const defaultCardStyle = {
    transition: `${duration}ms`,
  };

  const cardTransitionStyles: any = {
    entering: { transform: `translateY(0%)` },
    entered: { transform: `translateY(100%)` },
    exiting: { transform: `translateY(100%)` },
    exited: { transform: `translateY(0%)` },
  };

  return (
    <div>
      <Transition
        in={props.opened}
        transition={duration}
        unmountOnExit
        addEndListener={(node, done) => {
          node.addEventListener('transitionend', done, false);
        }}
      >
        {(state) => (
          <BackgroundWrapper
            style={{ ...defaultBackgroundStyle, ...backgroundTransitionStyles[state] }}
          ></BackgroundWrapper>
        )}
      </Transition>
      <Transition
        in={props.opened}
        transition={duration}
        unmountOnExit
        addEndListener={(node, done) => {
          node.addEventListener('transitionend', done, false);
        }}
      >
        {(state) => (
          <CardWrapper style={{ ...defaultCardStyle, ...cardTransitionStyles[state] }}>
            <TopWrapper></TopWrapper>
            {props.children}
          </CardWrapper>
        )}
      </Transition>
    </div>
  );
};
export default Modal;

import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import ReactGA from 'react-ga';
import Barcode from 'react-barcode';
import P from '../../components/P';
import {
  StyledP,
  Wrapper,
  StyledClub,
  ContentWrapper,
  StyledCode,
  StyledWallet,
  StyledWalletBtn,
  StyledWalletIcons,
} from './UserCard.style';
import { isRtlLang } from 'rtl-detect';
import { LocaleContext } from '../../contexts/Locale';
import { executeRecaptcha } from '../../services/recaptcha-enterprise';
import { ConfigContext } from '../../contexts/Config';
import { addToWalletEnabled, sendLoyaltyCardByEmail } from '../../services/apis/connect';
import { useQuery } from '../../hooks/useQuery';
import appleImg from '../../assets/images/card/apple-wallet.png';
import googleImg from '../../assets/images/card/google-pay.png';

const UserCard = () => {
  const intl = useIntl();
  const location = useLocation<any>();
  const { language } = useContext(LocaleContext);
  const { config } = useContext(ConfigContext);
  const club = new URLSearchParams(window.location.search).get('club');
  const query = useQuery();
  const history = useHistory();
  const mailSent = location.state.mailSent;
  const [walletEnabled, setWalletEnabled] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const getRecaptchaToken = () =>
    JSON.parse(config.GOOGLE_RECAPTCHA_ACTIVE)
      ? executeRecaptcha('sendCard', config.GOOGLE_RECAPTCHA_ENTERPRISE_KEY)
      : Promise.resolve(null);

  useEffect(() => {
    addToWalletEnabled(config)
      .then((enabled) => {
        setWalletEnabled(enabled?.data || false);
        setLoaded(true);
      })
      .catch((error) => {
        console.error(error);
        setLoaded(true);
      });
  }, [config]);

  useEffect(() => {
    if (!location.state.signup && !mailSent) {
      ReactGA.event({
        category: 'signup',
        action: 'existingAccount',
        label: 'receiveMyCardByEmail',
      });
      try {
        getRecaptchaToken().then((recaptchaToken) => {
          sendLoyaltyCardByEmail(
            {
              email: location.state.email,
              uuid: location.state.uuid,
              country: query.get('country') as string,
              store: query.get('store') as string,
            },
            config,
            language,
            recaptchaToken
          );
          history.replace(`/user-card${location.search}`, {
            ...location.state,
            mailSent: true,
          });
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  const saveCard = () => {
    history.push(`/user-card-save${location.search}`, {
      ...location.state,
    });
  };

  return (
    loaded && (
      <Wrapper>
        <ContentWrapper>
          <h4 className="vtmn-typo_title-4">
            {intl.formatMessage({ id: 'PAGES.USER_CARD.TITLE' })}
          </h4>
          {club && (
            <StyledClub id="club_notif">
              <span className="vtmx-checkbox-circle-line"></span>
              <P className={isRtlLang(language as string) ? 'club-rtl' : ''}>
                {intl.formatMessage({ id: 'PAGES.USER_CARD.CLUB_NOTIF' })}
              </P>
            </StyledClub>
          )}
          <StyledP>{intl.formatMessage({ id: 'PAGES.USER_CARD.SUBTITLE' })}</StyledP>
          {walletEnabled && (
            <StyledWallet id="add-to-wallet_info">
              <h5 className="vtmn-typo_title-5">
                {intl.formatMessage({ id: 'PAGES.USER_CARD.ADD_TO_WALLET_INFO' })}
              </h5>
              <StyledWalletIcons>
                <img alt="apple wallet" src={appleImg} />
                <img alt="google pay" src={googleImg} />
              </StyledWalletIcons>
            </StyledWallet>
          )}
          <Barcode
            format="CODE128"
            value={location.state.cardNumber}
            displayValue={false}
            margin={0}
            id="barcode_code"
          />
          <StyledCode id="barcode_number">{location.state.cardNumber}</StyledCode>
          {walletEnabled && (
            <StyledWalletBtn>
              <button className="vtmn-btn" onClick={saveCard} id="add-to-wallet_button">
                {intl.formatMessage({ id: 'PAGES.USER_CARD.ADD_TO_WALLET' })}
              </button>
            </StyledWalletBtn>
          )}
        </ContentWrapper>
      </Wrapper>
    )
  );
};

export default UserCard;

import styled from 'styled-components';
import Div100vhWithHeader from '../../components/Div100WithHeader';
import Title from '../../components/Title';
import P from '../../components/P';
import { Link } from 'react-router-dom';

const Wrapper = styled(Div100vhWithHeader)`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0 auto;

  svg {
    margin: 2.5rem auto;
    border-radius: 6px;
    box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    height: auto;
    min-height: 80px;

    @media (max-width: 380px) {
      margin: 1rem auto;
    }
  }
`;

const StyledIcon = styled.span`
  margin: 2.5rem auto 0;
  width: 28px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  background-color: var(--vtmn-color_danger);
  border-radius: 50%;
`;

const StyledTitle = styled(Title)`
  margin-top: 1rem;
  text-align: center;
`;

const StyledP = styled(P)`
  margin-top: 1.25rem;
`;

const StyledButton = styled.button`
  margin-top: 1.5rem;
  min-height: var(--button-height);
  width: 100%;
`;

const StyledLink = styled(Link)`
  margin-top: 2.5rem;
  text-align: center;
  color: var(--vtmn-color_brand-digital);
  font-weight: var(--vtmn-typo_font-weight--bold);
  text-decoration: none;
`;

export { Wrapper, StyledIcon, StyledTitle, StyledP, StyledButton, StyledLink };

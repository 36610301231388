import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  StyledTitle,
  Wrapper,
  StyledSubTitle,
  StyledP,
  StyledLink,
  StyledSuccess,
} from './ClubMail.style';
import clubMailImg from '../../assets/images/consent/club_mail.svg';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { ConfigContext } from '../../contexts/Config';
import { sendClubConsentMail } from '../../services/apis/connect';
import Picture from '../../components/Picture';

const ClubMail = (): JSX.Element => {
  const intl = useIntl();
  const location = useLocation<any>();
  const history = useHistory();
  const { config } = useContext(ConfigContext);
  const [showLink, setShowLink] = useState(true);

  const mailSent = location.state.mailSent;

  const sendMail = useCallback(() => {
    const payload = {
      email: location.state.email,
      uuid: location.state.uuid,
    };
    sendClubConsentMail(payload, config).then(() => showLinkOnSuccess());
  }, [location, config]);

  useEffect(() => {
    if (!mailSent) {
      sendMail();
      history.replace(`/club-mail${location.search}`, {
        ...location.state,
        mailSent: true,
      });
    }
  }, [sendMail, mailSent, history, location]);

  const showLinkOnSuccess = () => {
    setShowLink(false);
    const timer = setTimeout(() => setShowLink(true), 60000);
    return () => clearTimeout(timer);
  };

  return (
    <Wrapper>
      <Picture src={clubMailImg} alt={intl.formatMessage({ id: 'PAGES.CLUB_MAIL.TITLE' })} />
      <StyledSubTitle className="vtmn-typo_title-5">
        {intl.formatMessage({ id: 'PAGES.CLUB_MAIL.SUBTITLE' })}
      </StyledSubTitle>
      <StyledTitle className="vtmn-typo_title-3">
        {intl.formatMessage({ id: 'PAGES.CLUB_MAIL.TITLE' })}
      </StyledTitle>
      <StyledP>{intl.formatMessage({ id: 'PAGES.CLUB_MAIL.INFO' })}</StyledP>
      {showLink && (
        <StyledLink className="vtmn-typo_text-3">
          {intl.formatMessage({ id: 'PAGES.CLUB_MAIL.MAIL_NOT_SENT' })}
          <span
            className="vtmn-link vtmn-link--standalone vtmn-link_size--small"
            id="club_mail_send"
            onClick={sendMail}
          >
            {intl.formatMessage({ id: 'PAGES.CLUB_MAIL.SEND_NEW_MAIL' })}
          </span>
        </StyledLink>
      )}
      {!showLink && (
        <StyledSuccess className="vtmn-typo_text-3">
          {intl.formatMessage({ id: 'PAGES.CLUB_MAIL.MAIL_SENT' })}
          <span> {location.state.email}</span>
        </StyledSuccess>
      )}
    </Wrapper>
  );
};

export default ClubMail;

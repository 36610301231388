import React from 'react';
import {
  Wrapper,
  StyledIcon,
  StyledTitle,
  StyledP,
  StyledLink,
  StyledButton,
} from './InvalidClubMail.style';
import { useIntl } from 'react-intl';
import ReactHtmlParser from 'react-html-parser';
import { useHistory, useLocation } from 'react-router-dom';

const InvalidClubMail = () => {
  const intl = useIntl();
  const location = useLocation();
  const history = useHistory();

  const redirectToClub = () => {
    history.push(`/club${location.search}`, location.state);
  };

  return (
    <Wrapper>
      <StyledIcon color="white" />
      <StyledTitle>
        {ReactHtmlParser(intl.formatMessage({ id: 'PAGES.INVALID_CLUB_MAIL.TITLE' }))}
      </StyledTitle>
      <StyledP>{intl.formatMessage({ id: 'PAGES.INVALID_CLUB_MAIL.INFO' })}</StyledP>
      <StyledLink
        to={{ pathname: '/club-mail', search: location.search, state: location.state }}
        id="invalid_club_mail_send"
      >
        {intl.formatMessage({ id: 'PAGES.INVALID_CLUB_MAIL.SEND_NEW_MAIL' })}
      </StyledLink>
      <StyledButton onClick={redirectToClub} id="invalid_club_mail_about">
        {intl.formatMessage({ id: 'PAGES.INVALID_CLUB_MAIL.CLUB_DETAILS' })}
      </StyledButton>
    </Wrapper>
  );
};

export default InvalidClubMail;

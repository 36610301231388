import styled from 'styled-components';
import Div100vhWithHeader from '../../components/Div100WithHeader';

const Wrapper = styled(Div100vhWithHeader)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const EmailNotFound = styled.h3`
  margin: 0;
  text-align: center;
`;

const StyledP = styled.p`
  margin-top: 0.75rem;
`;

const StyledButton = styled.button`
  width: 100%;
  margin-top: 1.5rem;
`;

const NeedInfo = styled.h5`
  margin-top: 1.5rem;
`;

const StyledImg = styled.img`
  position: absolute;
  bottom: 0;
  left: -2rem;
  min-width: calc(100% + 4rem);
  max-height: 400px;

  @media (max-width: 380px) {
    left: 0;
    min-width: 100%;
  }
`;

export { Wrapper, EmailNotFound, StyledP, StyledButton, NeedInfo, StyledImg };

import styled from 'styled-components';
import Div100vhWithHeader from '../../components/Div100WithHeader';

const Wrapper = styled(Div100vhWithHeader)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

const StyledTitle = styled.h5`
  text-align: center;
`;

const UserInfoWrapper = styled.div`
  margin-top: 0.75rem;
`;

const LowercaseUserInfo = styled.div`
  margin: 0;
  font-weight: var(--vtmn-typo_font-weight--regular);
  & p {
    text-transform: lowercase;
  }
`;

const CapitalizeUserInfo = styled.div`
  margin: 0;
  font-weight: var(--vtmn-typo_font-weight--regular);
  & p::first-letter {
    text-transform: capitalize;
  }
`;

const UserInfoLabel = styled.label`
  font-weight: var(--vtmn-typo_font-weight--bold);

  &:after {
    content: ' : ';
  }
`;

const UserInfo = styled.p`
  display: inline-block;
  margin: 0;
`;

const InfoText = styled.p`
  margin-top: 1rem;
`;

const EmailWrapper = styled.div`
  width: fit-content;
  margin: 1.5rem auto 0;
  padding: 0.75rem;
  background-color: var(--vtmn-color_white);
  box-shadow: 0 0 24px rgba(0, 83, 125, 0.12);
  font-size: var(--vtmn-typo_text-1-font-size);
  border-radius: 0.5rem;

  & p {
    display: inline-block;
  }
`;

const AskWrapper = styled.p`
  margin-top: 1.5rem;
  text-align: center;
  font-weight: var(--vtmn-typo_font-weight--bold);
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  margin: 2.5rem 0 1.5rem;

  & button {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    white-space: pre-wrap;
    font-weight: var(--vtmn-typo_font-weight--regular);

    & span {
      font-size: 2rem;
      color: var(--vtmn-color_grey);
      margin-bottom: 0.25rem;
    }
  }
`;

const TopButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;

  & button {
    width: calc(50% - 0.375rem);
  }
`;

const BottomButton = styled.button`
  margin-top: 0.75rem;
  width: 100%;
`;

const ValidIcon = styled.span`
  color: var(--vtmn-color_success) !important;
`;

const InvalidIcon = styled.span`
  color: var(--vtmn-color_danger) !important;
`;

export {
  Wrapper,
  StyledTitle,
  UserInfoWrapper,
  CapitalizeUserInfo,
  UserInfoLabel,
  UserInfo,
  LowercaseUserInfo,
  InfoText,
  EmailWrapper,
  AskWrapper,
  ButtonsWrapper,
  TopButtonsWrapper,
  BottomButton,
  ValidIcon,
  InvalidIcon,
};
